import { ActionType } from "../actions/artists";
import { Reducer } from "redux";
import {
  ArtistEventAttributes,
  FeedModulesAttributes,
  TabsAttributes,
} from "@/shared/models/strapi";
import {
  Artist,
  ArtistHighlights,
  ArtistPost,
  FanWallPost,
} from "@/shared/models";

interface State {
  artistData: Artist | null;
  sideModules: FeedModulesAttributes[];
  topModules: FeedModulesAttributes[];
  posts: ArtistPost[];
  tabs: TabsAttributes[];
  highlights: ArtistHighlights[];
  events: ArtistEventAttributes[];
  fanWallPosts: FanWallPost[];
  isDraft: boolean;
  isFollower: boolean;
  isBacker: boolean;
}

const initialState: State = {
  artistData: null,
  sideModules: [],
  topModules: [],
  posts: [],
  tabs: [],
  highlights: [],
  events: [],
  fanWallPosts: [],
  isDraft: false,
  isFollower: false,
  isBacker: false,
};

const artistReducer: Reducer<State, ActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SET_SIDE_FEED_MODULES":
      return {
        ...state,
        sideModules: action.payload.feedModules,
      };

    case "SET_TOP_FEED_MODULES":
      return {
        ...state,
        topModules: action.payload.feedModules,
      };
    case "SET_POSTS":
      return {
        ...state,
        posts: action.payload.posts,
      };
    case "SET_TABS":
      return {
        ...state,
        tabs: action.payload.tabs,
      };
    case "SET_HIGHLIGHTS":
      return {
        ...state,
        highlights: action.payload.highlights,
      };
    case "SET_EVENTS":
      return {
        ...state,
        events: action.payload.events,
      };
    case "SET_FAN_WALL_POSTS":
      return {
        ...state,
        fanWallPosts: action.payload.posts,
      };
    case "SET_ARTIST":
      return {
        ...state,
        artistData: action.payload.artist,
      };
    case "SET_DRAFT":
      return {
        ...state,
        isDraft: action.payload,
      };
    case "SET_IS_FOLLOWER":
      return {
        ...state,
        isFollower: action.payload,
      };
    case "ADD_NEW_FOLLOWER":
      return {
        ...state,
        isFollower: action.payload.isFollower,
        artistData: state.artistData
          ? {
              ...state.artistData,
              followers:
                action.payload.isFollower && state.artistData
                  ? [...state.artistData?.followers, action.payload.user]
                  : state.artistData?.followers.filter(
                      (u) => u.id !== action.payload.user.id
                    ),
            }
          : null,
      };
    case "SET_IS_BACKER":
      return {
        ...state,
        isFollower: action.payload,
      };
    default:
      return state;
  }
};

export default artistReducer;
