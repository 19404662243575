import { MetamaskError } from "@/shared/models/external";
import { Problem } from "@/shared/models/api";
import { AxiosError } from "axios";

const isApiProblem = (error: unknown): error is Problem => {
  return (
    error !== null &&
    typeof error === "object" &&
    "status" in error &&
    "type" in error &&
    "title" in error
  );
};

const isAxiosError = (error: unknown): error is AxiosError => {
  return error !== null && typeof error === "object" && "isAxiosError" in error;
};

export const getErrorMessage = (error: unknown): string => {
  if (isAxiosError(error)) {
    const response = error.response?.data;

    if (isApiProblem(response)) {
      return getErrorMessage(response);
    }

    return error.message;
  }

  if (isApiProblem(error)) {
    return error.detail ?? error.title;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return "An unknown error occurred, try again later";
};

export const isMetamaskError = (error: unknown): error is MetamaskError => {
  const metamaskError = error as MetamaskError;
  return (
    metamaskError &&
    !!metamaskError.error &&
    "message" in metamaskError.error &&
    "code" in metamaskError.error &&
    metamaskError.error.message.startsWith("execution reverted: Artist: ")
  );
};
