import { KLOOV_SEO_URL } from "@/shared/constants/KloovUrl";
import { KLOOV_OPEN_GRAPH_IMAGE } from "@/shared/constants/path";

let url = KLOOV_SEO_URL;

const SEO = {
  titleTemplate: "%s | Kloov",
  defaultTitle: "Kloov",
  description:
    "KLOOV offers artists an easy way to create digital collectibles (NFT) and deliver meaningful experiences, building bridges for fans to interact with music in the most innovative ways. So we invite you to Join the Web3 revolution, supporting your favorite artists on KLOOV, because music is the real thing.",
  canonical: url,
  openGraph: {
    type: "website",
    locale: "en_US",
    url,
    site_name: "Kloov",
    title: "Kloov | Music NFT",
    description:
      "KLOOV offers artists an easy way to create digital collectibles (NFT) and deliver meaningful experiences, building bridges for fans to interact with music in the most innovative ways. So we invite you to Join the Web3 revolution, supporting your favorite artists on KLOOV, because music is the real thing.",
    images: [
      {
        url: KLOOV_OPEN_GRAPH_IMAGE,
        alt: "kloov's logo",
        width: 500,
        height: 500,
      },
    ],
  },
  additionalRobotsProps: [
    {
      nosnippet: false,
      notranslate: false,
      noimageindex: false,
      noarchive: true,
      maxImagePreview: "standard",
    },
  ],
  additionalMetaTags: [
    {
      name: "facebook-domain-verification",
      content: "cn6vg12ce0bla6q5y7moek81uvpsd3",
    },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1, maximum-scale=1",
    },
  ],
  additionalLinkTags: [
    {
      rel: "icon",
      sizes: "16x16",
      href: "/favicon-16.png",
      type: "image/png",
    },
    {
      rel: "icon",
      sizes: "32x32",
      href: "/favicon-32.png",
      type: "image/png",
    },
    {
      rel: "icon",
      sizes: "48x48",
      href: "/favicon-48.png",
      type: "image/png",
    },
    {
      rel: "icon",
      sizes: "192x192",
      href: "/favicon-192.png",
      type: "image/png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "167x167",
      href: "/favicon-167.png",
      type: "image/png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/favicon-180.png",
      type: "image/png",
    },
  ],
  facebook: {
    appId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || "",
  },
  twitter: {
    handle: "@GoKloov",
    site: "@GoKloov",
    cardType: "summary_large_image",
  },
  site_name: "Kloov",
};

export default SEO;
