export const pinataURL = "https://gateway.pinata.cloud/ipfs";

export const resellFee = 1000;

export const addressBytesLength = 20;

export const ItemPurchasedEvent =
  "ItemPurchased(uint256,uint256,uint256,address)";

export const TransferEvent = "Transfer(address,address,uint256)";

export const ItemPurchaseEvent =
  "ItemPurchased(uint256,uint256,uint256,address)";

export const ArtistCreatedEvent = "ArtistCreated(uint256,address)";

export const ItemCreatedEvent =
  "ItemCreated(uint256,uint256,uint256,uint256,uint256)";

export const CHAIN_INFO = {
  POLYGON: {
    TESTNET: {
      name: "maticmum",
      openseaName: "mumbai",
      alchemyBaseUrl: "https://polygon-mumbai.g.alchemy.com/v2",
      formattedName: "Matic Mumbai",
      chainId: "0x13881",
      currency: "MATIC",
      scannerUrl: "https://mumbai.polygonscan.com",
      icon: {
        source: "/static/icons/matic.svg",
        small: {
          width: 18,
          height: 18,
        },
        large: {
          width: 28,
          height: 28,
        },
      },
      format: {
        textColor: "white",
      },
      priceFeedAddress: "0xd0D5e3DB44DE05E9F294BB0a3bEEaF030DE24Ada",
    },
    MAINNET: {
      name: "matic",
      openseaName: "matic",
      alchemyBaseUrl: "https://polygon-mainnet.g.alchemy.com/v2",
      formattedName: "Matic Mainnet",
      chainId: "0x89",
      currency: "MATIC",
      scannerUrl: "https://polygonscan.com",
      icon: {
        source: "/static/icons/matic.svg",
        small: {
          width: 18,
          height: 18,
        },
        large: {
          width: 28,
          height: 28,
        },
      },
      format: {
        textColor: "white",
      },
      priceFeedAddress: "",
    },
  },
  ETHEREUM: {
    TESTNET: {
      name: "goerli",
      openseaName: "goerli",
      alchemyBaseUrl: "https://eth-goerli.alchemyapi.io/v2",
      formattedName: "Goerli Testnet",
      chainId: "0x5",
      currency: "ETH",
      scannerUrl: "https://goerli.etherscan.io",
      icon: {
        source: "/static/icons/eth.svg",
        small: {
          width: 12,
          height: 16,
        },
        large: {
          width: 16,
          height: 22,
        },
      },
      format: {
        textColor: "brand",
      },
      priceFeedAddress: "",
    },
    MAINNET: {
      name: "homestead",
      openseaName: "ethereum",
      alchemyBaseUrl: "https://eth-mainnet.alchemyapi.io/v2",
      formattedName: "Ethereum Mainnet",
      chainId: "0x1",
      currency: "ETH",
      scannerUrl: "https://etherscan.io",
      icon: {
        source: "/static/icons/eth.svg",
        small: {
          width: 22,
          height: 22,
        },
        large: {
          width: 22,
          height: 22,
        },
      },
      format: {
        textColor: "brand",
      },
      priceFeedAddress: "",
    },
  },
};

export const successfulTransaction = 1;

export const onKloov = "on Kloov";

export const weiToEth = 10 ** -18;

export const minWertPrice = 0.002;

export const gasMultiplier = 1.1;

export const gasLimitTransferFrom = 60000;

//PreRelease events
export const PreReleaseCreatedEvent = "PreReleaseCreated(uint256,address)";
export const FanCardMintedEvent = "FanCardMinted(uint256,address)";
