import classNames from "classnames";
import { FC, ReactNode } from "react";

interface Props {
  id: string | undefined;
  className?: string;
  children: ReactNode;
  hasFocus?: boolean;
}

const Label: FC<Props> = ({ id, children, className, hasFocus = false }) => (
  <label
    htmlFor={id}
    className={classNames(
      className,
      "text-13 font-inter leading-normal font-medium",
      hasFocus ? "text-white" : "text-gray-800"
    )}
  >
    {children}
  </label>
);

export default Label;
