import axios from "axios";
import { ThunkActionCreator } from "../../types";
import { closeNotification, openNotification } from "../notification";

const client = axios.create({ baseURL: "/api/auth" });

export type ActionType = ReturnType<typeof sendVerificationEmailAction>;

const sendVerificationEmailAction = (isSent: boolean) => {
  return {
    type: "SEND_EMAIL" as const,
    payload: isSent,
  };
};

export const sendVerificationEmail: ThunkActionCreator = (email: string) => {
  return async (dispatch) => {
    try {
      await client.post("/send-verification-email", { email });
      await dispatch(
        openNotification({
          title: "Email verification sent!",
          description:
            "We've sent you a verification email, if you can't see it in your inbox, please check your spam folder",
          btnText: "Close",
          onClick: () => dispatch(closeNotification()),
        })
      );
      return dispatch(sendVerificationEmailAction(true));
    } catch (e) {
      await dispatch(
        openNotification({
          title: "Error Verifying your email",
          description: "Please try again later",
          btnText: "Send verification email",
          onClick: () => dispatch(sendVerificationEmail(email)),
        })
      );
      throw e;
    }
  };
};
