import Script from "next/script";
import { FC } from "react";
const NextScripts: FC = () => {
  return (
    <>
      {process.env.NEXT_PUBLIC_VERCEL_ENV === "production" && (
        <>
          {/* Inspectlet */}
          <Script type="text/javascript" id="inspectletjs">
            {`(function() {
          window.__insp = window.__insp || [];
          __insp.push(['wid', 1190840753]);
          var ldinsp = function(){
          if(typeof window.__inspld != "undefined") 
          return; window.__inspld = 1; var insp = document.createElement('script'); 
          insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; 
          insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + 
          '://cdn.inspectlet.com/inspectlet.js?wid=1190840753&r=' + Math.floor(new Date().getTime()/3600000); 
          var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
          setTimeout(ldinsp, 0);
          })();`}
          </Script>

          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
          />

          {/* Global site tag (gtag.js) - Google Analytics */}
          <Script id="google-analytics" strategy="afterInteractive">
            {`window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');`}
          </Script>

          {/* Google tag manager */}
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`}
          </Script>

          {/* Facebook pixel */}
          <Script id="facebook-pixel">
            {`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID});
          fbq('track', 'PageView');`}
          </Script>
        </>
      )}

      <Script
        dangerouslySetInnerHTML={{
          __html: `
            WebFontConfig = {
          google: {
              families: ['Poppins:300,400,700,900','Inter:300,400,700']
          }
      };
            `,
        }}
      />

      <Script
        strategy="afterInteractive"
        src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"
      />

      <Script src="https://accounts.google.com/gsi/client" async defer />

      {/* Hoast 360 */}
      <Script src="https://hoast.iem.at/staticfiles/mediadb/js/hoast360.bundle.js" />

      {process.env.NEXT_PUBLIC_VERCEL_ENV === "production" && (
        /* Inspectlet */
        <Script type="text/javascript">
          {`window.$crisp=[];window.CRISP_WEBSITE_ID="af309e75-f2cf-4663-a999-09db56b8cabc";
        (function(){ d=document;s=d.createElement("script"); s.src="https://client.crisp.chat/l.js"; 
        s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
      `}
        </Script>
      )}
    </>
  );
};

export default NextScripts;
