export interface Notification {
  title: string;
  description?: string | JSX.Element;
  btnText?: string;
  image?: string;
  bgImage?: string;
  onClick?: (e: React.MouseEvent) => void;
  status?: TransactionStatus;
  transactionHash?: string;
  closeOnRouteChange?: boolean;
  closeOnBtnClick?: boolean;
  buyerAddress?: string;
  chain?: string;
  type?: NotificationType;
  extraInfo?: {
    image?: string;
    text: string;
  };
  closeDisabled?: boolean;
}

export enum NotificationType {
  TRANSACTION = "TRANSACTION",
  STRIPE_PAYMENT = "STRIPE_PAYMENT",
}

export type TransactionStatus = "SUCCESS" | "PENDING" | "FAILED";

export const getTransactionMessage = (status: TransactionStatus) => {
  switch (status) {
    case "SUCCESS":
      return "Completed!";
    case "FAILED":
      return "Failed. Something went wrong";
    case "PENDING":
      return "Pending";
    default:
      return "";
  }
};
