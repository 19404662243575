import React, { FC } from "react";
import Image from "next/image";
import { getBoringAvatar } from "../utils/boringAvatars";

interface Props {
  name: string;
  id?: string;
  size?: number;
}

const AvatarWrapper: FC<Props> = ({ id, name, size = 32 }) => {
  return (
    <div id={id} className="rounded-full overflow-hidden">
      <Image
        width={size}
        height={size}
        src={getBoringAvatar(name)}
        alt={`${name}-avatar`}
      />
    </div>
  );
};

export default AvatarWrapper;
