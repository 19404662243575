// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://82251b5e9f3f4f569e2c8808b45a22e4@o1220637.ingest.sentry.io/6363645",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV ?? "local",
  normalizeDepth: 5, // For redux, see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
});
