import { Artist, Nft, PreRelease, User } from "@/shared/models";
import axios from "axios";
import { useState } from "react";

const useSearchBar = () => {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [artistResult, setArtistResult] = useState<Artist[]>([]);
  const [itemsResult, setItemsResult] = useState<(Nft | PreRelease)[]>([]);
  const [usersResult, setUsersResult] = useState<User[]>([]);
  const [noResults, setNoResults] = useState(false);

  const searchData = async (query: string) => {
    //Call backend to query
    cleanSearchData();
    setLoadingSearch(true);
    const { data } = await axios.get(`/api/search?query=${query}`);

    setArtistResult(data.result.artists);
    setItemsResult(data.result.items);
    setUsersResult(data.result.users);

    if (
      data.result.artists.length > 0 &&
      data.result.items.length > 0 &&
      data.result.users.length > 0
    ) {
      setNoResults(false);
    } else {
      setNoResults(true);
    }
    setLoadingSearch(false);
  };

  const cleanSearchData = () => {
    //Call backend to query
    setArtistResult([]);
    setItemsResult([]);
  };
  return {
    loadingSearch,
    artistResult,
    usersResult,
    itemsResult,
    noResults,
    searchData,
    cleanSearchData,
  };
};

export default useSearchBar;
