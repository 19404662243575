import { z } from "zod";

export const SignupInput = z
  .object({
    firstname: z.string().min(1).max(255),
    lastname: z.string().min(1).max(255),
    email: z.string().email().max(255),
    password: z.string().min(8).max(36),
    country: z.string().min(2).max(2),
    countryId: z.number(),
    isArtist: z.boolean().optional(),
    artistName: z.string().min(1).max(255).optional(),
  })
  .strict()
  .refine((data) => {
    if (data.isArtist) {
      return !!data.artistName;
    }

    return true;
  });

export type SignupInput = z.infer<typeof SignupInput>;

export const SigninInput = z
  .object({
    email: z.string().email().max(255),
    password: z.string().min(8).max(36),
    rememberMe: z.boolean().optional(),
  })
  .strict();

export type SigninInput = z.infer<typeof SigninInput>;

export const EmailInput = z
  .object({
    email: z.string().email().max(255),
  })
  .strict();

export type EmailInput = z.infer<typeof EmailInput>;

export const PasswordTokenInput = z
  .object({
    password: z.string().min(8).max(36),
    token: z.string().length(100),
    resetPasswordCode: z.string(),
  })
  .strict();

export type PasswordTokenInput = z.infer<typeof PasswordTokenInput>;

export const GoogleAuthInput = z
  .object({
    email: z.string().email().max(255),
    sub: z.string().min(1),
    name: z.string().min(1),
    firstname: z.string().min(1),
    lastname: z.string().min(1),
    picture: z.string().url().optional(),
  })
  .strict();

export type GoogleAuthInput = z.infer<typeof GoogleAuthInput>;
