import { useEffect, useState, ChangeEvent, useRef } from "react";
import { Router } from "next/router";
import Image from "next/image";
import Link from "next/link";
import Button from "./Button";
import { useLoginContext, useProfileModal } from "../redux/selectors";
import FixedContainer from "../layouts/FixedContainer";
import { useDispatch } from "react-redux";
import {
  closeProfileModal,
  openProfileModal,
} from "../redux/actions/profileModal";
import MenuModal from "./MenuModal";
import { DEFAULT_IMAGE_PLACEHOLDER } from "@/shared/constants/path";
import { getAvatar } from "@/shared/utils/images";
import { IoIosArrowDown, IoIosMenu } from "react-icons/io";
import { useIsProduction } from "../hook/useIsProduction";
import AvatarWrapper from "./AvatarWrapper";
import SearchBar from "./SearchBar";
import { isDefaultAvatar } from "../utils/boringAvatars";
import useSearchBar from "../hook/useSearchBar";
import SearchResultMenu from "./SearchResultMenu";
import useGoogleAuth from "../hook/useGoogleAuth";
import { useExpandNavbar } from "../redux/selectors/navbar";

const NavBar = () => {
  const dispatch = useDispatch();
  const isProduction = useIsProduction();
  const { loading, loginContext } = useLoginContext();
  const {
    loadingSearch,
    artistResult,
    itemsResult,
    usersResult,
    noResults,
    searchData,
    cleanSearchData,
  } = useSearchBar();
  const open = useProfileModal();

  const isNavbarExpanded = useExpandNavbar();

  const connectRef = useRef<HTMLButtonElement>(null);

  useGoogleAuth(true);

  const [expandSearchBar, setExpandSearchBar] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    const onRouteChangeComplete = () => {
      setExpandSearchBar(false);
      setSearchQuery("");
      document.body.classList.remove("fixed");
    };
    Router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () =>
      Router.events.off("routeChangeComplete", onRouteChangeComplete);
  }, []);

  const handleOpenProfileModal = async () => {
    if (!open) {
      setIsOpened(true);
      dispatch(openProfileModal());
    } else {
      setIsOpened(false);
      dispatch(closeProfileModal());
    }
  };

  useEffect(() => {
    const closeModal = async (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (
        (isOpened && !loading && open && !target.closest("#modal")) ||
        (isOpened &&
          connectRef.current &&
          open &&
          connectRef.current.contains(target as Node))
      ) {
        await dispatch(closeProfileModal());
      }

      if (!target.closest("#navbar")) {
        setExpandSearchBar(false);
        setShowSearchResult(false);
      }
    };

    document.body.addEventListener("mousedown", closeModal);

    return () => {
      document.body.removeEventListener("mousedown", closeModal);
    };
  }, [dispatch, open]);

  const handleSetQuery = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
    if (value.length >= 4) {
      setShowSearchResult(true);
      await searchData(value);
    } else {
      cleanSearchData();
      setShowSearchResult(false);
    }
  };

  return (
    <header
      id="navbar"
      className="fixed  top-[0.5rem] md:top-[0.5rem] w-full bg-transparent z-[50] "
    >
      <FixedContainer className="relative z-40 flex flex-col  items-end md:items-end">
        {/* Small screen */}
        <div className="xs:hidden w-full relative bg-black bg-opacity-40 drop-shadow-lg  px-[10px] rounded-[8px] flex flex-row justify-between items-center h-[60px]">
          {!expandSearchBar && (
            <Link passHref href="/">
              <a>
                <div className="flex self-center cursor-pointer">
                  <Image
                    src="/static/logo/kloov_white.svg"
                    alt="kloov white brand logo"
                    height="20"
                    width="87.7"
                  />
                </div>
              </a>
            </Link>
          )}

          <div
            className={`flex ${
              expandSearchBar && "w-full"
            } space-x-[10px] self-center`}
          >
            <div className="relative">
              <SearchBar
                expanded={expandSearchBar}
                setExpanded={setExpandSearchBar}
                className={"h-[40px]"}
                value={searchQuery}
                setValue={handleSetQuery}
              />
            </div>
            <Button
              id="navbar-menu-button"
              ref={connectRef}
              onClick={handleOpenProfileModal}
              inverted
              className={`flex justify-between items-center w-[80px] h-[40px] pl-[10px] pr-[5.2px] py-[10px] rounded-[8px] border-none ${
                loginContext.user
                  ? "bg-gray-450 text-brand"
                  : "bg-brand text-gray-450"
              }`}
            >
              <IoIosMenu size={28} />
              {loginContext.user ? (
                <>
                  {!loginContext.user.avatar &&
                  isDefaultAvatar(loginContext.user.avatar) ? (
                    <AvatarWrapper name={loginContext.user.username} />
                  ) : (
                    <Image
                      src={getAvatar(loginContext.user.avatar)}
                      alt={`${loginContext.user.username} image`}
                      height={32}
                      width={32}
                      objectFit="cover"
                      blurDataURL={DEFAULT_IMAGE_PLACEHOLDER}
                      placeholder="blur"
                      className="rounded-full object-cover"
                    />
                  )}
                </>
              ) : (
                <>
                  <Image
                    src="/static/icons/avatar.svg"
                    alt={`avatar image`}
                    height={32}
                    width={32}
                    objectFit="cover"
                    blurDataURL={DEFAULT_IMAGE_PLACEHOLDER}
                    placeholder="blur"
                    className="rounded-full object-cover"
                  />
                </>
              )}
            </Button>
          </div>
          {showSearchResult && (
            <SearchResultMenu
              loading={loadingSearch}
              onClose={() => setShowSearchResult(false)}
              noResults={noResults}
              artistResult={artistResult}
              itemsResult={itemsResult}
              usersResult={usersResult}
            />
          )}
        </div>

        {/* Big screen */}
        <div
          className={`bg-black  bg-opacity-40 ${
            isNavbarExpanded && "w-full"
          } shadow-lg  px-[10px] rounded-[8px] hidden xs:flex space-x-5 justify-between items-center  h-[60px]`}
        >
          <Link passHref href="/">
            <a>
              <div className="flex self-center cursor-pointer">
                <Image
                  className="object-contain"
                  src="/static/logo/kloov_white_navbar.svg"
                  alt="kloov white brand icon"
                  height={40}
                  width={92}
                />
              </div>
            </a>
          </Link>

          <nav className="flex max-w-[90%] items-center space-x-[10px] text-white">
            <div className="relative">
              <SearchBar
                className="h-[40px]"
                expanded={expandSearchBar}
                setExpanded={setExpandSearchBar}
                value={searchQuery}
                setValue={handleSetQuery}
              />
              {showSearchResult && (
                <SearchResultMenu
                  loading={loadingSearch}
                  noResults={noResults}
                  onClose={() => setShowSearchResult(false)}
                  artistResult={artistResult}
                  itemsResult={itemsResult}
                  usersResult={usersResult}
                />
              )}
            </div>

            <Button
              id="navbar-menu-button"
              ref={connectRef}
              onClick={handleOpenProfileModal}
              inverted
              className={`flex justify-between items-center w-[80px] h-[40px] pl-[10px] pr-[5.2px] py-[10px] rounded-[8px] border-none ${
                loginContext.user
                  ? "bg-gray-450 text-brand"
                  : "bg-brand text-gray-450"
              }`}
            >
              <IoIosMenu size={28} />
              {loginContext.user ? (
                <>
                  {!loginContext.user.avatar &&
                  isDefaultAvatar(loginContext.user.avatar) ? (
                    <AvatarWrapper name={loginContext.user.username} />
                  ) : (
                    <Image
                      src={getAvatar(loginContext.user.avatar)}
                      alt={`${loginContext.user.username} image`}
                      height={32}
                      width={32}
                      objectFit="cover"
                      blurDataURL={DEFAULT_IMAGE_PLACEHOLDER}
                      placeholder="blur"
                      className="rounded-full object-cover"
                    />
                  )}
                </>
              ) : (
                <>
                  <Image
                    src="/static/icons/avatar.svg"
                    alt={`avatar image`}
                    height={32}
                    width={32}
                    objectFit="cover"
                    blurDataURL={DEFAULT_IMAGE_PLACEHOLDER}
                    placeholder="blur"
                    className="rounded-full object-cover"
                  />
                </>
              )}
            </Button>
          </nav>
        </div>
        <MenuModal
          isOpenedMenu={isOpened}
          buttonRef={connectRef}
          loading={loading}
          loginContext={loginContext}
        />
      </FixedContainer>
    </header>
  );
};

export default NavBar;
