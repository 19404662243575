import { FC } from "react";
import Image from "next/image";
import {
  DISCORD,
  INSTAGRAM,
  LINKEDIN,
  TELEGRAM,
  TWITTER,
} from "@/shared/constants/social_media";
import Link from "next/link";
import PatternBackground from "./PatternBackground";

interface Props {
  className?: string;
}

const Footer: FC<Props> = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="z-30 flex flex-col items-center px-3 py-7 mt-10 bg-black">
      <div className="flex mb-7 space-x-5">
        <a target="_blank" href={INSTAGRAM} rel="noreferrer">
          <Image
            src="/static/icons/instagram.svg"
            alt="instagram icon"
            height="30"
            width="30"
            aria-label="instagram icon"
            className="object-contain opacity-[0.87]"
          />
        </a>

        <a target="_blank" href={TWITTER} rel="noreferrer">
          <Image
            src="/static/icons/twitter.svg"
            alt="twitter icon"
            height="30"
            width="30"
            aria-label="twitter icon"
            className="object-contain opacity-[0.87]"
          />
        </a>

        <a target="_blank" href={LINKEDIN} rel="noreferrer">
          <Image
            src="/static/icons/linkedIn.svg"
            alt="linkedin icon"
            height="30"
            width="30"
            aria-label="linkedin icon"
            className="object-contain opacity-[0.87]"
          />
        </a>

        <a target="_blank" href={TELEGRAM} rel="noreferrer">
          <Image
            src="/static/icons/telegram.svg"
            alt="discord icon"
            height="30"
            width="30"
            aria-label="discord icon"
            className="object-contain opacity-[0.87]"
          />
        </a>

        <a target="_blank" href={DISCORD} rel="noreferrer">
          <Image
            src="/static/icons/discord.svg"
            alt="discord icon"
            height="30"
            width="30"
            aria-label="discord icon"
            className="object-contain opacity-[0.87]"
          />
        </a>
      </div>

      <div className="flex flex-col items-center text-white mb-2">
        <small className="font-inter text-12">
          <span className="font-semibold">© {year} KLOOV.</span> All right
          reserved.
        </small>

        <nav className="flex flex-row items-center justify-center space-x-2">
          <Link passHref href="/terms-of-service">
            <a>
              <small className="font-inter leading-loose underline">
                Terms of use
              </small>
            </a>
          </Link>
          <span>-</span>
          <Link passHref href="/privacy-policy">
            <a>
              <small className="font-inter leading-loose underline">
                Privacy Policy
              </small>
            </a>
          </Link>
          <span>-</span>
          <Link passHref href="/faq">
            <a>
              <small className="font-inter leading-loose underline">FAQs</small>
            </a>
          </Link>
          <span>-</span>
          <Link passHref href="/about-us">
            <a>
              <small className="font-inter leading-loose underline">
                About us
              </small>
            </a>
          </Link>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
