export type ActionType = ReturnType<
  typeof openProfileModal | typeof closeProfileModal
>;

export const openProfileModal = () => {
  return {
    type: "OPEN_PROFILE_MODAL" as const,
    payload: true,
  };
};

export const closeProfileModal = () => {
  return {
    type: "CLOSE_PROFILE_MODAL" as const,
    payload: false,
  };
};
