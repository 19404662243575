import { ActionType } from "../actions/klip";
import { Reducer } from "redux";
import { KlipMedia } from "@/shared/models";

interface State {
  currentKlip: KlipMedia | null;
}

const initialState: State = {
  currentKlip: null,
};

const klipReducer: Reducer<State, ActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SET_KLIP":
      return {
        ...state,
        currentKlip: action.payload.klip,
      };
    case "CLEAR_KLIP":
      return {
        ...state,
        klip: null,
      };
    default:
      return state;
  }
};

export default klipReducer;
