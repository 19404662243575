import { NftType, Artist, Nft, PreRelease } from "./../models/index";
import getNetwork from "./getNetwork";

export function getNftUrl(nft: Pick<Nft, "artist" | "slug" | "type">) {
  return `/${nft.artist.username}/${
    nft.type === NftType.FULLTRACK
      ? "xclusivs"
      : nft.type === NftType.KLIP
      ? "klips"
      : "basics"
  }/${nft.slug}`;
}

export function getAvantsUrl(nft: PreRelease) {
  return `/${nft.artist.username}/avants/${nft.slug}`;
}

export function getArtistUrl(username: string) {
  return `/${username}`;
}

export function getArtistDraftUrl(username: string) {
  return `/${username}/draft`;
}

export function getUserUrl(username: string) {
  return `/u/${username}`;
}

export function getNftExternalUrl(
  artist: Pick<Artist, "username">,
  songSlug: string,
  isKlip?: boolean
) {
  return `${process.env.KLOOV_EXTERNAL_URL}/${artist.username}/${
    isKlip ? "klips" : "xclusivs"
  }/${songSlug}`;
}

export function getAvantsExternalUrl(
  artist: Pick<Artist, "username">,
  songSlug: string
) {
  return `${process.env.KLOOV_EXTERNAL_URL}/${artist.username}/avants/${songSlug}`;
}

export function getEtherscanTransactionUrl(txHash: string, chain: string) {
  return `${getNetwork(chain).scannerUrl}/tx/${txHash}`;
}

export function getEtherscanAddressUrl(address: string, chain: string) {
  return `${getNetwork(chain).scannerUrl}/address/${address}`;
}

export const getNft360PlayerUrl = (slug: string) => {
  return `/360player?slug=${slug}`;
};

export const getTransferNftUrl = (slug: string, tokenId: number) => {
  return `/u/transfer-nft?slug=${slug}&tokenid=${tokenId}`;
};

export const getIpfsMediaUrl = (ipfsHash: string) => {
  return `https://gateway.pinata.cloud/ipfs/${ipfsHash}`;
};
