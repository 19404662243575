import classNames from "classnames";
import React, { FC } from "react";

interface Props {
  className?: string;
}
const PatternBackground: FC<Props> = ({ className, children }) => {
  const _class = classNames(
    "absolute bg-grid-pattern bg-no-repeat bg-center bg-cover bottom-[-40px] z-[-1] w-[98.5vw] scrollbar-hide  h-[550px]",
    className
  );
  return <div className={_class}>{children}</div>;
};

export default PatternBackground;
