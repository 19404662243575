import { CHAIN_INFO } from "../constants/NFT";
import { ChainInfo } from "../models";

const getNetwork = (network = "ethereum"): ChainInfo => {
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
  if (network === "polygon")
    return isProduction
      ? CHAIN_INFO.POLYGON.MAINNET
      : CHAIN_INFO.POLYGON.TESTNET;
  else
    return isProduction
      ? CHAIN_INFO.ETHEREUM.MAINNET
      : CHAIN_INFO.ETHEREUM.TESTNET;
};

export default getNetwork;
