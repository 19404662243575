import {
  AritstReleaseAttributes,
  ArtistEventAttributes,
  BandsInTownDataAttributes,
  DiscountCodeAttributes,
  FanWallPostAttributes,
  FeedModulesAttributes,
  GenreAttributes,
  LaunchPartyAttributes,
  ReleasedLinksAttributes,
  SocialMediaAttributes,
  SpotifyDataAttributtes,
  TabsAttributes,
  UserAttributes,
} from "./strapi";

/**
 * Model Artist
 *
 */

export enum Chain {
  ETH = "ethereum",
  MATIC = "polygon",
}

export enum WalletOption {
  KLOOV = "kloov",
  METAMASK = "metamask",
}

export interface ChainInfo {
  name: string;
  openseaName: string;
  formattedName: string;
  alchemyBaseUrl: string;
  chainId: string;
  currency: string;
  scannerUrl: string;
  icon: {
    source: string;
    large: {
      width: number;
      height: number;
    };
    small: {
      width: number;
      height: number;
    };
  };
  format?: {
    textColor: string;
  };
  priceFeedAddress: string;
}

export enum ArtistProfileRoles {
  PUBLIC = "Public",
  USER = "User",
  BACKER = "Backer",
  FOLLOWER = "Follower",
  VIP = "VIP",
  TEAM = "Team",
}
export interface Artist {
  id: number;
  name: string;
  description: string | null;
  username: string;
  avatar: string;
  cover: string;
  blockChainAddress: string | null;
  genres: GenreAttributes[];
  createdAt: string;
  updatedAt: string;
  socialMedia: SocialMedia | SocialMediaAttributes | null;
  nftAddress: string;
  kloovWallet: string | null;
  strapiId: number | null;
  chain: Chain;
  tabs: TabsAttributes[];
  followers: User[];
  highlights: ArtistHighlights[];
  sideModules: FeedModulesAttributes[];
  topModules: FeedModulesAttributes[];
  posts: ArtistPost[];
  launchParties: LaunchPartyAttributes[];
  sponsor: Sponsor | null;
  claimed: boolean;
  releases: AritstReleaseAttributes[];
  events: ArtistEventAttributes[];
  spotifyData: SpotifyDataAttributtes | null;
  bandsInTownData: BandsInTownDataAttributes | null;
  fanWallPosts: FanWallPost[];
}

export enum FeedPostType {
  ALL = "all",
  POST = "posts",
  ITEM = "items",
  RELEASE = "releases",
}

export enum BackersFiltersType {
  BACKERS = "Backers",
  FOLLOWERS = "Followers",
}

export enum ArtistEventsType {
  ALL = "All",
  CONCERT = "Concerts",
  FESTIVAL = "Festival",
  ONLINE = "Online Events",
}

export interface ArtistPost {
  id: number;
  content: string;
  createdAt: string;
  media: string | null;
  link: string | null;
  linkText: string | null;
  role: ArtistProfileRoles;
}

export interface FanWallPost {
  id: number;
  message: string;
  user: User;
  artist: Artist;
}

export enum HighlightType {
  ITEM = "Item",
  LINK = "Link",
  EVENT = "Event",
}

export interface ArtistHighlights {
  id: number;
  title: string;
  text: string;
  media: string | null;
  link: string;
  type: HighlightType;
  date?: string | null;
  itemType?: string | null;
}

export interface Sponsor {
  id: number;
  brandName: string;
  logo: string;
  link: string;
}

/**
 * Model Genre
 *
 */
export interface Genre {
  code?: string;
  name?: string;
}

/**
 * Model Country
 *
 */
export interface Country {
  id: number;
  code: string;
  name: string;
}

/**
 * Model ArtistManagement
 *
 */
export interface ArtistManagement {
  id: number;
  permissions: string;
  artistId: number | null;
  userId: number | null;
  createdAt?: string;
  updatedAt?: string;
}

export enum UserRole {
  ADMIN = "admin",
  USER = "user",
}

/**
 * Model User
 *
 */

export interface InstagramVerification {
  id: number;
  userId: number;
  instagramUserId: string;
  accessToken: string;
  username: string;
  profileImage: string;
}
export interface User {
  id: number;
  firstname: string;
  lastname: string;
  fullname: string;
  email: string;
  username: string;
  countryCode: string;
  avatar: string | null;
  totalRaised: number;
  role: string;
  description: string | null;
  spotifyPlaylist: string | null;
  copies: number[];
  createdAt: string;
  updatedAt: string;
  isVerified: boolean;
  kloovWallet: string | null;
  artist: Artist | null;
  socialMedia: SocialMedia | null;
  instagramData: InstagramVerification | null;
  stripeCustomerId: string | null;
  isVip?: boolean | null;
}

export enum NftType {
  FULLTRACK = "fulltrack",
  KLIP = "klip",
  PRERELEASE = "avants",
  BASICS = "basics",
}

export interface WhatsInside {
  icon: string;
  text: string;
  description: string;
}

export interface KlipMedia {
  copyId: number;
  source: string;
  userId: number | null;
}

export enum BasicsType {
  LAUNCH_PARTY = "launch-party",
  FAN_CARD = "fan-card",
}

/**
 * Model (NftCollection) - Nft
 *
 */

export interface Nft {
  id: number;
  name: string;
  description: string;
  cover: string;
  price: number;
  fullTrackId: number;
  kloovFee: number;
  startDate: string;
  endDate: string;
  slug: string;
  lyrics: string | null;
  nftCount: number;
  artist: Artist;
  cloudflareId: string;
  status: "draft" | "active" | "inactive";
  nftCopy: Pick<NftCopy, "tokenId" | "userAddress" | "copyId" | "sellDate">;
  nftCountSold: number;
  createdAt: string;
  updatedAt: string;
  strapiId: number | null;
  artistAddress: string;
  type: NftType;
  lastTokenSold: number;
  klips: KlipMedia[];
  whatsInside: WhatsInside[];
  hasVideo: boolean;
  isHighlighted?: boolean;
  isOpenEdition: boolean;
  animated?: string;
  basicsType?: BasicsType;
  linkedAvants?: PreRelease | null;
}

/**
 * Model (Nft) - NftCopy
 *
 */
export interface NftCopy {
  id: number;
  copyId: number;
  nftId: number;
  sellDate: string;
  userId: number;
  userAddress: string;
  tokenId: number;
  cloudflareId: string;
}

export enum PAYMENT_METHOD {
  CARD = "card",
  CRYPTO_CURRENCY = "crypto_currency",
}

/**
 * Model SellHistory
 *
 */
export interface SellHistory {
  id: number;
  sell_date: string;
  price: number;
  buyer_address: string;
  nftCopyId: number | null;
  paymentMethod: PAYMENT_METHOD | null;
}

/**
 * Model PreRelease
 *
 */

export interface StripePreReleaseData {
  price: number;
  productId: string;
}

export interface FanCard {
  nftAddress: string;
  price: number;
  whatsInside: WhatsInside[];
}

export interface FanCardCopy {
  id: number;
  tokenId: number;
  preReleaseId: number;
  sellDate: string;
  userId: number;
  userAddress: string;
}

export interface PreReleaseSponsor {
  brandName: string;
  link: string;
  logo: string;
}

export interface PreRelease {
  id: number;
  name: string;
  description: string;
  cover: string;
  price: number;
  fanCard: FanCard;
  startDate: string;
  endDate: string;
  slug: string;
  artist: Artist;
  cloudflareId: string;
  createdAt: string;
  updatedAt: string;
  strapiId: number | null;
  whatsInside: WhatsInside[];
  listenDate: string;
  isHighlighted?: boolean;
  glbModel?: string;
  fanCardCopy: FanCardCopy | null;
  linkedXclusiv: Nft | null;
  releasedLinks: ReleasedLinksAttributes | null;
  poweredBy: PreReleaseSponsor | null;
  discountCodes: DiscountCodeAttributes[] | null;
  certificate?: PreReleaseCertificate | null;
}

export interface PreReleaseCertificate {
  id: number;
  preReleaseId: number;
  userId: number;
  certificateUrl: string;
}

export interface PreReleaseToken {
  id: number;
  token: string;
  preRelease: PreRelease;
  user_id: number;
  isUsed: boolean;
  createdAt: string;
  expiresAt: string;
}

export interface SocialMedia {
  id: number;
  spotify?: string;
  twitter?: string;
  instagram?: string;
  website?: string;
}

export enum SecureTokenType {
  RESET_PASSWORD = "reset-password",
  VALIDATION = "validation",
}
export interface SecureToken {
  id: number;
  token: string;
  userId: number;
  isUsed: boolean;
  type: SecureTokenType;
  createdAt: string;
  expiresAt: string | null;
}
export interface Event {
  id: number;
  startDate: string;
  name: string;
  description?: string;
}

export interface PayToken {
  id: number;
  name: string;
  address: string;
  priceFeed: string;
  icon: string;
  decimals: number;
  chain: Chain;
}

/**
 * Model ExclusiveContent
 *
 */
export interface ExclusiveContent {
  id: number;
  nftId: number;
  url: string;
  ambisonicsOrder: number;
}

export interface Item {
  itemId: number;
  supply: number;
  artist: string;
  owners: string[];
  price: number;
  startTime: number;
  nftContract: string;
}

export interface Links {
  href: string;
  name: string;
}

export interface LoginContext {
  user: User | null;
  notifications: any[];
  artist: Artist | null;
}

export interface Tab {
  id: number;
  title: string;
  content: () => JSX.Element | null;
  display?: boolean;
}

interface TeamComponentType {
  name: string;
  position: string;
  image: string;
}

interface StrapiImage {
  url: string;
  width: number;
  height: number;
}
export interface SupportComponentType {
  name: string;
  description: string;
  image: StrapiImage | null;
  url: string;
}
export interface AboutUsType {
  slogan: string;
  purpose: string;
  supports: SupportComponentType[];
  team: TeamComponentType[];
}

export enum UserSections {
  COLLECTED = "COLLECTED",
  PRE_RELEASES = "PRE_RELEASES",
}

export enum ArtistSections {
  COMMUNITY = "COMMUNITY",
  FEED = "FEED",
  STORE = "STORE",
  EVENTS = "EVENTS",
  LIVE = "LIVE",
  FANWALL = "FANWALL",
}

export interface FeedContentBlock {
  date: string;
  content: (Nft | PreRelease | ArtistPost | AritstReleaseAttributes)[];
}
