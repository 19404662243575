import { Reducer } from "redux";
import { ActionType } from "../actions/send-email/index";

interface sendEmailState {
  isSent: boolean;
}

const initialState: sendEmailState = {
  isSent: false,
};

const sendVerificationEmailReducer: Reducer<sendEmailState, ActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SEND_EMAIL":
      return {
        ...state,
        isSent: action.payload,
      };
    default:
      return state;
  }
};

export default sendVerificationEmailReducer;
