import Image from "next/image";
import Router from "next/router";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { FC, useEffect, useState } from "react";
import { useProfileModal } from "../redux/selectors";
import {
  getArtistUrl,
  getNftUrl,
  getAvantsUrl,
  getUserUrl,
} from "@/shared/utils/createUrls";
import { Artist, Nft, PreRelease, User } from "@/shared/models";
import { getBoringAvatar } from "../utils/boringAvatars";
import useScrollDetection from "../hook/useScrollDetection";

interface Props {
  onClose: () => void;
  artistResult: Artist[];
  itemsResult: (Nft | PreRelease)[];
  usersResult: User[];
  loading: boolean;
  noResults: boolean;
}

const SearchResultMenu: FC<Props> = ({
  loading,
  onClose,
  artistResult,
  itemsResult,
  usersResult,
  noResults,
}) => {
  const dispatch = useDispatch();

  useScrollDetection(450, onClose);

  const open = useProfileModal();

  useEffect(() => {
    const closeModal = async (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (open && !target.closest("#modal")) {
        onClose();
      }
    };

    document.body.addEventListener("mousedown", closeModal);
    return () => document.body.removeEventListener("mousedown", closeModal);
  }, [dispatch, open]);

  useEffect(() => {
    const handleCloseDialog = async () => {
      onClose();
    };

    Router.events.on("routeChangeComplete", handleCloseDialog);
    return () => Router.events.off("routeChangeComplete", handleCloseDialog);
  }, [dispatch]);

  return (
    <section
      id="search-modal"
      className="absolute font-inter rounded-sm z-50 inset-x-0 top-[60px]  md:inset-auto  md:mt-[15px] drop-shadow-2xl md:right-0 w-full md:w-[343px] bg-[#222222] "
    >
      {!loading ? (
        <div className="flex text-white flex-col  py-2 my-[13px]">
          {/*  {noResults && <span className="ml-[19px]">No Results Found</span>} */}
          {artistResult.length > 0 && (
            <>
              <h1 className="text-[#9b9b9b] text-[13px] font-medium ml-[19px]">
                Kloovs
              </h1>
              <div className="flex flex-col text-white py-4 space-y-4 px-[5px]">
                <>
                  {artistResult.map((artist) => {
                    return (
                      <Link
                        key={artist.id}
                        href={getArtistUrl(artist.username)}
                        passHref
                      >
                        <div className="cursor-pointer flex space-x-5 items-center hover:bg-[#37383e] py-[10px] px-[10px]">
                          <div className="relative w-[50px] h-[50px]">
                            <Image
                              layout="fill"
                              src={artist.avatar}
                              className="rounded-full object-cover"
                              alt={`search-${artist.name}`}
                            />
                          </div>
                          <div className="flex flex-col items-start justify-center">
                            <div className="flex gap-2 items-center text-[13px] font-extrabold">
                              <h1 className="leading">{artist.name}</h1>
                              {artist.claimed && (
                                <Image
                                  src="/static/icons/icon_tick.svg"
                                  width={11}
                                  height={11}
                                  alt="tick"
                                />
                              )}
                            </div>
                            <p className="text-[11px] text-[#9b9b9b]">
                              @{artist.username}
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </>
              </div>
            </>
          )}
          {itemsResult.length > 0 && (
            <>
              <h1 className=" ml-[19px] mt-[7px] text-[#9b9b9b] text-[13px] font-medium">
                Items
              </h1>
              <div className="flex flex-col text-white py-4 space-y-4 px-[5px]">
                {itemsResult.map((nft) => {
                  const isNft = "type" in nft;
                  return (
                    <Link
                      key={nft.id}
                      href={isNft ? getNftUrl(nft) : getAvantsUrl(nft)}
                      passHref
                    >
                      <div className=" cursor-pointer flex space-x-5 items-center hover:bg-[#37383e] py-[10px] px-[10px]">
                        <div className="relative w-[48px] h-[48px]">
                          <Image
                            layout="fill"
                            src={nft.cover}
                            className="rounded-[4px] object-cover"
                            alt={`search-${nft.name}`}
                          />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                          <div className="flex gap-2 items-center text-[13px] font-extrabold">
                            <h1 className="leading">{nft.name}</h1>
                          </div>
                          <p className="text-[11px] text-[#9b9b9b]">
                            by {nft.artist.name}
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </>
          )}
          {usersResult.length > 0 && (
            <>
              <h1 className=" ml-[19px] mt-[7px] text-[#9b9b9b] text-[13px] font-medium">
                Users
              </h1>
              <div className="flex flex-col text-white py-4 space-y-4 px-[5px]">
                {usersResult.map((user) => {
                  return (
                    <Link
                      key={user.id}
                      href={getUserUrl(user.username)}
                      passHref
                    >
                      <div className=" cursor-pointer  flex space-x-[7px] items-center hover:bg-[#37383e] py-[5px] px-[5px]">
                        <div className="relative w-[26px] h-[26px]">
                          <Image
                            layout="fill"
                            src={user.avatar ?? getBoringAvatar(user.username)}
                            className="rounded-full object-cover"
                            alt={`search-${user.username}`}
                          />
                        </div>

                        <div className="flex gap-2 items-center text-[13px] font-extrabold">
                          <h1 className="leading">
                            {user.firstname} {user.lastname}
                          </h1>
                        </div>
                        <p className="text-[14px]">·</p>
                        <p className="text-[11px] text-[#9b9b9b]">
                          @{user.username}
                        </p>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center  divide-y-2 px-4 py-2 divide-gray-300/20 my-5">
          <div className="w-8 h-8 border-b-2 border-r-2 border-brand rounded-full animate-spin"></div>
        </div>
      )}
    </section>
  );
};

export default SearchResultMenu;
