import { SigninInput } from "@/shared/validators/authentication";
import Link from "next/link";
import React, { useState, ChangeEvent, FC } from "react";
import { MdArrowBack } from "react-icons/md";
import { useDispatch } from "react-redux";
import { login } from "../redux/actions/loginContext";
import { closeProfileModal } from "../redux/actions/profileModal";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Input from "./Input";

interface Props {
  onClose: () => void;
}
const SignInModal: FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState<SigninInput>({
    email: "",
    password: "",
    rememberMe: false,
  });
  const isValid = SigninInput.safeParse(input).success;

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInput((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onRememberMeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput((state) => ({
      ...state,
      rememberMe: e.target.checked,
    }));
  };

  const onSubmit = async () => {
    await dispatch(login(input));
    dispatch(closeProfileModal());
  };

  const handleClose = () => {
    setInput({
      email: "",
      password: "",
      rememberMe: false,
    });
    onClose();
  };

  return (
    <div className="relative">
      <div className="relative flex flex-col mb-7 items-center text-white gap-5 w-full self-center">
        <div
          onClick={handleClose}
          className=" cursor-pointer absolute hidden md:flex md:top-0 left-0"
        >
          <MdArrowBack color="#DEDEDE" size={32} />
        </div>
        <h1 className="text-2xl font-poppins font-bold uppercase">
          Sign in to <span className="text-brand">Kloov</span>
        </h1>
        <Input
          className="w-full"
          name="email"
          type="email"
          label="Email address"
          value={input.email}
          onChange={onInputChange}
        />
        <Input
          className="w-full"
          name="password"
          label="Password"
          type="password"
          value={input.password}
          onChange={onInputChange}
        />
        <div className="flex flex-row justify-between w-full">
          <div>
            <Checkbox
              checked={input.rememberMe ?? false}
              onChange={onRememberMeChange}
              label="Remember me"
              name="rememberMe"
            />
          </div>
        </div>
        <Button
          primary
          onClick={onSubmit}
          disabled={!isValid}
          className="py-2 rounded-sm text-xs font-inter leading-normal font-medium w-full"
        >
          Sign in
        </Button>

        <Link href="/reset-password">
          <a className="text-[11px] underline text-brand font-inter leading-loose font-medium">
            Forgot your password?
          </a>
        </Link>
      </div>
      <div
        onClick={handleClose}
        className=" absolute flex md:hidden md:top-0 right-[50%] left-[40%]"
      >
        <MdArrowBack color="#DEDEDE" size={32} />
      </div>
    </div>
  );
};

export default SignInModal;
