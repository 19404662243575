import { Reducer } from "redux";
import { ActionType } from "../actions/profileModal/index";

interface ArtistState {
  open: boolean;
}

const initialState: ArtistState = {
  open: false,
};

const profileModalReducer: Reducer<ArtistState, ActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "OPEN_PROFILE_MODAL":
      return {
        ...state,
        open: action.payload,
      };
    case "CLOSE_PROFILE_MODAL":
      return {
        ...state,
        open: action.payload,
      };
    default:
      return state;
  }
};

export default profileModalReducer;
