import { isMetamaskError } from "@/client/utils/errors";
import { getRevertMessage } from "@/client/utils/getRevertMessage";
import { successfulTransaction } from "@/shared/constants/NFT";
import { getCDNUrl } from "@/shared/utils/images";
import { switchNetwork } from "@/shared/utils/switchNetwork";
import Router from "next/router";
import {
  getTransactionMessage,
  Notification,
} from "../../../utils/notification";
import { getMetamaskPhoneUrl } from "@/client/utils/getMetamaskPhoneUrl";
import getNetwork from "@/shared/utils/getNetwork";
import { Artist } from "@/shared/models";

export type ActionType = ReturnType<
  typeof openNotification | typeof closeNotification
>;

export const openNotification = (notification: Notification) => {
  return {
    type: "ADD_NOTIFICATION" as const,
    payload: notification,
  };
};

export const closeNotification = () => {
  return {
    type: "CLOSE_NOTIFICATION" as const,
    payload: null,
  };
};

export const openPendingTransactionNotification = (image: string) => {
  return openNotification({
    title: "Your transaction is being processed",
    status: "PENDING",
    image: getCDNUrl(image),
  });
};

export const openWaitForCountDownNotification = () => {
  return openNotification({
    title: "Purchase",
    description:
      "Hey! Once the countdown is finished you'll be able to listen to the song and also buy the NFT.\n \n" +
      "In the meantime you can get your Metamask Wallet ready and add some ETH(NFT price + gas fees) to your account.",
    btnText: "close",
    closeOnBtnClick: true,
  });
};

export const openNoRemainingNftNotification = () => {
  return openNotification({
    title: "NFT",
    description: "Sold out",
    btnText: "close",
    closeOnBtnClick: true,
  });
};

export const openEndTimeReachedNotification = () => {
  return openNotification({
    title: "NFT",
    description: "Puchase period has ended!",
    btnText: "close",
    closeOnBtnClick: true,
  });
};

export const openInstallMetamaskNotification = (isMobileOrTablet: boolean) => {
  return openNotification({
    title: "Please install Metamask first",
    description: isMobileOrTablet
      ? "Please make sure you're accessing kloov.com from the web browser inside your wallet app. If you don't have Metamask in your device please install and configure it first."
      : "Complete the transaction by installing Metamask Wallet",
    btnText: "Install",
    image: "/static/images/icon_metamask.png",
    closeOnBtnClick: true,
    onClick: async () => {
      window.open(
        getMetamaskPhoneUrl(`${window.location.hostname}${Router.asPath}`)
      );
    },
  });
};

export const openLoggedOutNotification = () => {
  return openNotification({
    title: "Please sign in first",
    description: "Complete the transaction by signing in",
    btnText: "Sign in",
    image: "/static/icons/icon_profile.png",
    closeOnBtnClick: true,
    onClick: async () => {
      await Router.push(`/signin?returnUrl=${Router.asPath}`);
    },
  });
};

export const openNotAllowedNetworkNotification = (artistChain: string) => {
  const network = getNetwork(artistChain);
  return openNotification({
    title: `Please switch to ${network.formattedName} Network`,
    description: `By entering the Network ${network.formattedName} you will be able to trade items within your Metamask Wallet`,
    btnText: "Switch Network",
    image: "/static/icons/display_network.png",
    closeOnBtnClick: true,
    onClick: async () => {
      await switchNetwork(network.name, network.chainId);
    },
  });
};

export const openWertCompleteNotification = (
  image: string,
  buyerAddress: string
) => {
  return openNotification({
    title: "Your transaction has been completed",
    description: "FullTrack purchased successfully",
    image: getCDNUrl(image),
    btnText: "Close",
    closeOnBtnClick: true,
    buyerAddress,
  });
};

export const openTransactionCompleteNotification = (
  result: any,
  image: string,
  chain: string
) => {
  const { status, transactionHash } = result;
  return openNotification({
    title:
      status === successfulTransaction
        ? "Your transaction has been completed"
        : "Oops something went wrong",
    status: status === successfulTransaction ? "SUCCESS" : "FAILED",
    description: getTransactionMessage(
      status === successfulTransaction ? "SUCCESS" : "FAILED"
    ),
    transactionHash: transactionHash,
    chain: chain,
    image: getCDNUrl(image),
    btnText: "OK",
    closeOnBtnClick: true,
  });
};

export const openUnsufficientFundsNotification = () => {
  return openNotification({
    title: "Insufficient funds",
    description: "Complete the transaction by adding more ETH to your wallet",
    btnText: "Ok",
    image: "/static/images/icon_metamask.png",
    closeOnBtnClick: true,
  });
};

export const openTransactionFailedNotification = (error: unknown) => {
  return openNotification({
    title: "Oops something went wrong",
    description: isMetamaskError(error)
      ? getRevertMessage(error.error.message)
      : "Please try again ",
    btnText: "OK",
    image: "/static/icons/error.png",
    closeOnBtnClick: true,
  });
};

export const openSoldOutNotification = (description: JSX.Element) => {
  return openNotification({
    title: "SOLD OUT",
    description,
  });
};

export const openFollowConsentNotification = (artist: Artist) => {
  return openNotification({
    title: `Disclaimer`,
    description: `By following this account I agree to receive communications or activity updates from ${artist.name}.`,
    btnText: "Close",
    closeOnBtnClick: true,
  });
};
