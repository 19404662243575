import React, { FC } from "react";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { Provider } from "react-redux";
import BaseLayout from "@/client/layouts/BaseLayout";
import store from "@/client/redux/store";
import "@/client/styles/globals.scss";
import "react-toggle/style.css";
import { DefaultSeo } from "next-seo";
import SEO from "../../next-seo.config";
import NextScripts from "@/client/components/NextScripts";
import "react-horizontal-scrolling-menu/dist/styles.css";

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <>
      <NextScripts />
      <DefaultSeo {...SEO} />
      <Provider store={store}>
        <BaseLayout>
          <TopProgressBar />
          <Component {...pageProps} />
        </BaseLayout>
      </Provider>
    </>
  );
};

const TopProgressBar = dynamic(
  () => {
    return import("@/client/components/TopProgressBar");
  },
  { ssr: false }
);

export default MyApp;
