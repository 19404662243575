import axios from "axios";
import { LoginContext } from "@/shared/models";
import { ApiResponse } from "@/shared/models/api";
import { SigninInput, SignupInput } from "@/shared/validators/authentication";
import { ThunkActionCreator } from "../../types";
import { closeNotification, openNotification } from "../notification";
import Router from "next/router";
import { getErrorMessage } from "@/client/utils/errors";
import { USER_IS_NOT_VERIFIED } from "@/shared/constants/messages";
import { sendVerificationEmail } from "./../send-email";
import { UploadInput } from "@/shared/validators/s3";
import { UserInput } from "@/shared/validators/user";
import { CreateWalletInput } from "@/shared/validators/kloovWallet";
import { getLoginContext } from "@/backend/utils/authentication";
import { IGoogleEndPointResponse } from "react-google-one-tap-login/dist/types/types";

const client = axios.create({ baseURL: "/api/auth" });

export type ActionType = ReturnType<
  | typeof setLoginContextAction
  | typeof startLoadingAction
  | typeof stopLoadingAction
  | typeof logoutAction
  | typeof updateUserAction
>;

const setLoginContextAction = (context: LoginContext) => {
  return {
    type: "AUTH_SET_LOGIN_CONTEXT" as const,
    payload: context,
  };
};

const startLoadingAction = () => {
  return {
    type: "AUTH_START_LOADING" as const,
  };
};

const stopLoadingAction = () => {
  return {
    type: "AUTH_STOP_LOADING" as const,
  };
};

const logoutAction = () => {
  return {
    type: "AUTH_LOGOUT" as const,
  };
};

const updateUserAction = (context: LoginContext) => {
  return {
    type: "UPDATE_USER" as const,
    payload: context,
  };
};

export const login: ThunkActionCreator = (
  input: SigninInput,
  returnUrl: string | undefined
) => {
  return async (dispatch) => {
    dispatch(startLoadingAction());

    try {
      const { data } = await client.post<ApiResponse<LoginContext>>(
        "/signin",
        input
      );
      if (returnUrl) {
        Router.push(`/${returnUrl}`);
      } else {
        Router.push("/");
      }

      return dispatch(
        setLoginContextAction({ ...data.result, notifications: [] })
      );
    } catch (e) {
      dispatch(stopLoadingAction());
      if (getErrorMessage(e).match(USER_IS_NOT_VERIFIED)) {
        await dispatch(
          openNotification({
            title: "Verify your email address",
            description:
              "If you haven't received any email from KLOOV in your inbox, please check your spam folder",
            image: "/static/icons/alert_email.svg",
            btnText: "Send verification email",
            onClick: () => dispatch(sendVerificationEmail(input.email)),
          })
        );
        return;
      }
      await dispatch(
        openNotification({
          title: "Error",
          description: getErrorMessage(e),
          status: "FAILED",
        })
      );
    }
  };
};

export const verifyUser: ThunkActionCreator = (
  input: CreateWalletInput,
  fromGoogle = false
) => {
  return async (dispatch) => {
    dispatch(startLoadingAction());
    try {
      await client.post("/verify-user", { ...input });
      if (fromGoogle) {
        await Router.push("/");
      } else {
        dispatch(
          openNotification({
            title: "Account verified successfully",
            description: "Please, sign in to continue",
            btnText: "Sign in",
            onClick: () => Router.push("/signin"),
          })
        );
      }
    } catch (e) {
      dispatch(stopLoadingAction());
      await dispatch(
        openNotification({
          title: "Error",
          description: getErrorMessage(e),
          status: "FAILED",
          btnText: "Sign in",
          onClick: () => Router.push("/signin"),
        })
      );
    }
  };
};

export const encryptWallet: ThunkActionCreator = (input: CreateWalletInput) => {
  return async (dispatch) => {
    dispatch(startLoadingAction());
    try {
      await client.post("/encrypt-wallet", { ...input });
      dispatch(
        openNotification({
          title: "Wallet encrypted successfully",
          description: "All Done! Your wallet has been succesfully encrypted",
          btnText: "Go to Homepage",
          onClick: () => Router.push("/"),
        })
      );
    } catch (e) {
      dispatch(stopLoadingAction());
      await dispatch(
        openNotification({
          title: "Error",
          description: getErrorMessage(e),
          status: "FAILED",
          btnText: "Try Again",
          onClick: () => dispatch(closeNotification()),
        })
      );
    }
  };
};

export const logout: ThunkActionCreator = () => {
  return async (dispatch) => {
    dispatch(startLoadingAction());

    try {
      await client.post<ApiResponse<boolean>>("/logout");

      return dispatch(logoutAction());
    } catch (e) {
      dispatch(stopLoadingAction());
      await dispatch(
        openNotification({
          title: "Error",
          description: getErrorMessage(e),
          status: "FAILED",
        })
      );
    }
  };
};

export const signup: ThunkActionCreator = (
  input: SignupInput,
  returnUrl: string | undefined
) => {
  return async (dispatch) => {
    dispatch(startLoadingAction());

    try {
      await client.post<ApiResponse<boolean>>("/signup", input);
      await dispatch(
        openNotification({
          title: "Email verification sent!",
          description:
            "Your account registration has been submitted successfully and is now pending email verification",
          btnText: "Close",
          onClick: () => dispatch(closeNotification()),
        })
      );
      if (returnUrl) {
        await Router.push(`/${returnUrl}`);
      }
    } catch (e) {
      dispatch(stopLoadingAction());
      await dispatch(
        openNotification({
          title: "Error",
          description: getErrorMessage(e),
          status: "FAILED",
        })
      );
      throw e;
    }
  };
};

export const fetchLoginContext: ThunkActionCreator = () => {
  return async (dispatch) => {
    dispatch(startLoadingAction());
    try {
      const { data } = await client.get<ApiResponse<LoginContext>>("/context");

      /*  if (data.result.user) {
        const {
          status,
          data: { result },
        } = await client.get(`/check-wallet?userId=${data.result.user.id}`);

        const path = Router.asPath;

        if (
          !result.done &&
          path !== "/u/encrypt-wallet" &&
          path !== "/verify-user" &&
          data.result.user.isVerified
        ) {
          await dispatch(
            openNotification({
              title: "Encrypt your wallet",
              description:
                "Please, we need you to encrypt your Kloov Wallet in order to be exported.",
              closeOnBtnClick: false,
              closeDisabled: true,
              btnText: "Go to Encrypt",
              onClick: () => Router.push("/u/encrypt-wallet"),
            })
          );
        }
      } */
      dispatch(stopLoadingAction());
      return dispatch(setLoginContextAction(data.result));
    } catch (e) {
      dispatch(stopLoadingAction());
      throw e;
    }
  };
};

export const updateUser: ThunkActionCreator = (
  userInput: UserInput,
  imageFile?: UploadInput
) => {
  return async (dispatch, getState) => {
    const userId = getState().loginContext.loginContext.user?.id;
    let avatar = null;

    try {
      if (!userId) {
        await dispatch(
          openNotification({
            title: "Please sign in first",
            btnText: "Sign in",
            onClick: async () => {
              await Router.push("/signin");
              await dispatch(closeNotification());
            },
          })
        );
        return;
      }

      // upload image to s3
      if (imageFile) {
        await dispatch(
          openNotification({
            title: "Image",
            description: "Uploading image...",
            status: "PENDING",
          })
        );

        //Change to strapi-upload
        const {
          data: { result },
        } = await axios.post("/api/s3/upload-image", imageFile);
        await axios.put(result.url, imageFile.file, {
          headers: {
            "Content-type": imageFile.contentType,
          },
        });
        // upload image url to database
        avatar = result.path;
        await dispatch(
          openNotification({
            title: "Image",
            description: "Image uploaded successfully",
            status: "SUCCESS",
          })
        );
      }

      // update profile
      await dispatch(
        openNotification({
          title: "Profile",
          description: "Updating profile",
          status: "PENDING",
        })
      );
      const { data } = await axios.put<ApiResponse<LoginContext>>(
        `/api/user/${userId}`,
        { ...userInput, avatar }
      );
      await dispatch(
        openNotification({
          title: "Profile",
          description: "Profile updated successfully",
          status: "SUCCESS",
        })
      );
      return dispatch(updateUserAction(data.result));
    } catch (e) {
      await dispatch(
        openNotification({
          title: "Error",
          description: "Unexpected error",
          status: "FAILED",
        })
      );
    }
  };
};

export const deleteUserImage: ThunkActionCreator = (userInput: UserInput) => {
  return async (dispatch, getState) => {
    const userId = getState().loginContext.loginContext.user?.id;
    if (!userId) {
      openNotification({
        title: "Please sign in first",
        btnText: "Sign in",
        onClick: async () => {
          await Router.push("/signin");
          await dispatch(closeNotification());
        },
      });
      return;
    }
    try {
      await dispatch(
        openNotification({
          title: "Profile",
          description: "Deleting profile image",
          status: "PENDING",
        })
      );
      const { data } = await axios.put<ApiResponse<LoginContext>>(
        `/api/user/${userId}`,
        userInput
      );
      await dispatch(
        openNotification({
          title: "Profile",
          description: "Profile image deleted successfully",
          status: "SUCCESS",
        })
      );
      return dispatch(updateUserAction(data.result));
    } catch (e) {
      await dispatch(
        openNotification({
          title: "Error",
          description: "Unexpected error",
          status: "FAILED",
        })
      );
    }
  };
};

export const verifyInstagram: ThunkActionCreator = (code: string) => {
  return async (dispatch) => {
    dispatch(startLoadingAction());
    try {
      const { data } = await axios.post("/api/auth/verify-instagram", { code });
      if (data.result.success) {
        dispatch(
          openNotification({
            title: "Instagram verified successfully",
            description: "",
            btnText: "Go to Settings",
            onClick: () => Router.push("/u/config/settings"),
          })
        );
      } else {
        dispatch(
          openNotification({
            title: "Unsuccessfully Instagram verified ",
            description: "Your account does not match the one you entered",
            btnText: "Go to profile",
            onClick: () => Router.push("/u/config/settings"),
          })
        );
      }
    } catch (e) {
      dispatch(stopLoadingAction());
      await dispatch(
        openNotification({
          title: "Error",
          description: getErrorMessage(e),
          status: "FAILED",
          btnText: "Go to Settings",
          onClick: () => Router.push("/u/config/settings"),
        })
      );
    }
  };
};

export const removeInstagram: ThunkActionCreator = () => {
  return async (dispatch) => {
    dispatch(startLoadingAction());
    try {
      const { data } = await axios.delete("/api/auth/remove-instagram");
      if (data.result.success) {
        dispatch(
          openNotification({
            title: "Instagram removed successfully",
            description: "",
            btnText: "Go to settings",
            onClick: () => Router.push("/u/config/settings"),
          })
        );
      } else {
        dispatch(
          openNotification({
            title: "Unsuccessfully removed Instagram",
            description: "A problem has occurred while removing",
            btnText: "Go to profile",
            onClick: () => Router.push("/u/config/settings"),
          })
        );
      }
    } catch (e) {
      dispatch(stopLoadingAction());
      await dispatch(
        openNotification({
          title: "Error",
          description: getErrorMessage(e),
          status: "FAILED",
          btnText: "Sign in",
          onClick: () => Router.push("/u/config/my-account"),
        })
      );
    }
  };
};

export const googleAuthAction: ThunkActionCreator = (
  googleResponse: IGoogleEndPointResponse
) => {
  return async (dispatch) => {
    dispatch(startLoadingAction());
    try {
      const {
        data: { result },
      } = await client.post<ApiResponse<any>>("/google-auth", {
        email: googleResponse.email,
        sub: googleResponse.sub,
        name: googleResponse.name,
        firstname: googleResponse.given_name,
        lastname: googleResponse.family_name,
        picture: googleResponse.picture,
      });

      dispatch(setLoginContextAction({ ...result, notifications: [] }));

      if (!result.login) {
        await Router.push(`/verify-user?from=google`);
      }
    } catch (e) {
      dispatch(stopLoadingAction());
      await dispatch(
        openNotification({
          title: "Error",
          description: getErrorMessage(e),
          status: "FAILED",
        })
      );
      throw e;
    }
  };
};
