import { isSSR } from "@/shared/utils/context";
import { parseJwt } from "@/shared/utils/jwt";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { googleAuthAction } from "../redux/actions/loginContext";
import { useLoginContext } from "../redux/selectors";

const useGoogleAuth = (useOneTap = false) => {
  const dispatch = useDispatch();
  const { loading, loginContext } = useLoginContext();
  const handleGoogleConnect = async (response: any) => {
    const responsePayload = parseJwt(response.credential);
    await dispatch(googleAuthAction(responsePayload));
  };
  useEffect(() => {
    const { user } = loginContext;

    if (!loading && !user && !isSSR() && window.google && useOneTap) {
      window.google.accounts.id.initialize({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? "",
        callback: handleGoogleConnect,
        prompt_parent_id: "navbar",
        cancel_on_tap_outside: true,
      });
    }
  }, [loginContext]);

  return { handleGoogleConnect };
};

export default useGoogleAuth;
