import classNames from "classnames";
import { FC, useEffect } from "react";
import { MdClose } from "react-icons/md";

interface Props {
  onClose?: () => void;
  isOpen: boolean;
  className?: string;
}

const Modal: FC<Props> = ({ isOpen, onClose, children, className }) => {
  useEffect(() => {
    const closeModal = async (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      /* if (isOpen && !target.closest("#modal")) {
        onClose && onClose();
      } */
    };

    document.body.addEventListener("click", closeModal);
    return () => document.body.removeEventListener("click", closeModal);
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const _class = classNames(
    "flex flex-col relative min-w-[343px] min-h-[389px] mx-auto bg-gray-card text-white rounded-sm",
    className
  );

  return (
    <div className="flex min-w-screen h-screen fixed justify-center items-center inset-0 z-[100]">
      <div className="absolute bg-black/80 inset-0" />
      <div id="modal" className={_class}>
        {onClose && (
          <MdClose
            className="absolute top-5 right-5 cursor-pointer"
            onClick={onClose}
            size={25}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
