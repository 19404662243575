import { isSSR } from "@/shared/utils/context";
import React, { FC, useEffect } from "react";
import useGoogleAuth from "../hook/useGoogleAuth";

interface Props {
  id: string;
  className?: string;
  width?: number;
}
const GoogleSignInButton: FC<Props> = ({ id, width, className }) => {
  const { handleGoogleConnect } = useGoogleAuth(false);

  useEffect(() => {
    if (!isSSR() && window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? "",
        callback: handleGoogleConnect,
      });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const googleLoginDiv: HTMLElement = document.getElementById(id)!;

      window.google.accounts.id.renderButton(googleLoginDiv, {
        type: "standard",
        shape: "circle",
        theme: "filled_black",
        size: "large",
        width: width,
      });
    }
  });
  return <div id={id} />;
};

export default GoogleSignInButton;
