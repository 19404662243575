import { ethers } from "ethers";
import { JsonRpcProvider } from "@ethersproject/providers";
import getNetwork from "@/shared/utils/getNetwork";

const getProvider = (artistChain = "ethereum") => {
  let provider: JsonRpcProvider;
  const network = getNetwork(artistChain);

  if (typeof window === "undefined" || !window.ethereum) {
    if (!network) {
      provider = new ethers.providers.JsonRpcProvider();
    } else {
      provider = new ethers.providers.JsonRpcProvider(
        `${network.alchemyBaseUrl}/${process.env.NEXT_PUBLIC_ALCHEMY_KEY}`
      );
    }
  } else {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  }
  return provider;
};

export default getProvider;
