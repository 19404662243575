import { DEFAULT_ARTIST_COVER, DEFAULT_AVATAR } from "../constants/path";

export function getCDNUrl(imagePath: string): string {
  // TODO: Remove once s3 url has been removed from DB
  if (imagePath.startsWith("https://")) {
    if (imagePath.includes("strapi-uploads")) {
      return imagePath;
    }
    if (imagePath.includes("og/avatar")) {
      return imagePath;
    }
    if (imagePath.includes("google")) {
      return imagePath;
    }

    return getCDNUrl(new URL(imagePath).pathname);
  }

  if (imagePath.includes("http") || imagePath.includes("static")) {
    return imagePath;
  }

  return `${process.env.NEXT_PUBLIC_CDN_URL}${imagePath}`;
}

export const getAvatar = (avatar: string | null) => {
  return avatar ? getCDNUrl(avatar) : DEFAULT_AVATAR;
};

export const getArtistCover = (cover: string | null) => {
  return cover ? getCDNUrl(cover) : DEFAULT_ARTIST_COVER;
};
