import { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Notification from "../components/Notification";
import PatternBackground from "../components/PatternBackground";
import useCurrentUrl from "../hook/useCurrentUrl";
import {
  fetchLoginContext,
  googleAuthAction,
} from "../redux/actions/loginContext";
import { useLoginContext } from "../redux/selectors";

import { IGoogleEndPointResponse } from "react-google-one-tap-login/dist/types/types";

const BaseLayout = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const { loading, loginContext } = useLoginContext();
  const currentUrl = useCurrentUrl();

  useEffect(() => {
    dispatch(fetchLoginContext());
  }, [dispatch]);

  const hideNavbar = currentUrl.includes("avants/listen");

  return (
    <div className="flex flex-col justify-between bg-gray-500  min-h-screen">
      <Notification />

      <NavBar />

      <main className="relative z-10 h-full">
        {children}
        <PatternBackground className="overflow bottom-0 w-full h-[520px]" />
      </main>

      <Footer />
    </div>
  );
};

export default BaseLayout;
