export const slugify = (term: string) => {
  return term
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w|\u0621-\u064A]/g, "-")
    .replace(/-{2,}/g, "-")
    .replace(/^-+|-+$/g, "")
    .toLowerCase();
};

export const normalizeUsername = (username: string) => {
  return username
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w|\u0621-\u064A]/g, "")
    .toLowerCase();
};

export const formatBlockChainAddress = (
  address: string,
  start = 14,
  end = 29
) => {
  return `${address.slice(0, start)}...${address.slice(end)}`;
};

export const formatBlockChainAddressTransferModal = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(38)}`;
};

export const formatTransactionHash = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(60)}`;
};

export const removeHtmlTags = (content: string) => {
  return content.replace(/<\/?[^>]+(>|$)/g, "");
};

export const removeStrangeCharacters = (text: string) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
