import { combineReducers } from "redux";
import profileModalReducer from "./profileModalReducer";
import loginContext from "./loginContextReducer";
import notificationReducer from "./notificationReducer";
import klipReducer from "./klipReducer";
import sendVerificationEmailReducer from "./sendVerificationEmailReducer";
import artistReducer from "./artistReducer";
import navbarReducer from "./navbarReducer";

const reducers = combineReducers({
  loginContext,
  notificationReducer,
  profileModalReducer,
  sendVerificationEmailReducer,
  klipReducer,
  artistReducer,
  navbarReducer,
});
export default reducers;

export type RootState = ReturnType<typeof reducers>;
