import Image from "next/image";
import { DEFAULT_IMAGE_PLACEHOLDER } from "@/shared/constants/path";
import Router, { useRouter } from "next/router";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { logout } from "../redux/actions/loginContext";
import { FC, RefObject, useEffect, useState } from "react";
import { useProfileModal } from "../redux/selectors";
import { LoginContext } from "@/shared/models";
import { getArtistUrl, getUserUrl } from "@/shared/utils/createUrls";
import { getAvatar } from "@/shared/utils/images";
import Button from "./Button";
import { getNavigationLinks } from "../utils/navigation";
import { closeProfileModal } from "../redux/actions/profileModal";
import AvatarWrapper from "./AvatarWrapper";
import SignInModal from "./SignInModal";
import { isDefaultAvatar } from "../utils/boringAvatars";
import Verified from "./icons/Verified";
import GoogleSignInButton from "./GoogleSignInButton";
import useScrollDetection from "../hook/useScrollDetection";
interface Props {
  isOpenedMenu: boolean;
  loginContext: LoginContext;
  loading: boolean;
  buttonRef: RefObject<HTMLButtonElement>;
}

const MenuModal: FC<Props> = ({
  isOpenedMenu,
  loginContext,
  loading,
  buttonRef,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const onScroll = async () => {
    await dispatch(closeProfileModal());
  };

  useScrollDetection(450, onScroll);

  const open = useProfileModal();

  const [showSignIn, setShowSignIn] = useState(false);

  const userNavigationLinks = getNavigationLinks(loginContext.user);

  const handleLogout = async () => {
    await dispatch(logout());
    dispatch(closeProfileModal());
    //router.reload();
  };

  useEffect(() => {
    const handleCloseDialog = async () => {
      await dispatch(closeProfileModal());
    };

    Router.events.on("routeChangeComplete", handleCloseDialog);
    return () => Router.events.off("routeChangeComplete", handleCloseDialog);
  }, [dispatch]);

  const linkClass =
    "flex flex-row text-gray-300 font-semibold space-x-5 cursor-pointer py-3";

  if (!open) {
    return null;
  }

  return (
    <section
      id="modal"
      className="rounded-sm z-50 inset-x-0  md:inset-auto  mt-[5px] drop-shadow-2xl md:right-0 md:w-[350px] bg-gray-500 md:border md:border-gray-400"
    >
      <div className="flex flex-col  divide-y-2 p-7 divide-gray-300/20 my-5">
        {loginContext.user && userNavigationLinks.length > 0 ? (
          <>
            <div className="flex flex-col gap-5 mb-5">
              <div className="flex flex-row justify-between items-center">
                <Link passHref href={getUserUrl(loginContext.user.username)}>
                  <a>
                    <div className="flex flex-row gap-x-2">
                      <div className="relative h-9 w-9">
                        {isDefaultAvatar(loginContext.user.avatar) ? (
                          <AvatarWrapper
                            size={36}
                            name={loginContext.user.username}
                          />
                        ) : (
                          <Image
                            src={getAvatar(loginContext.user.avatar)}
                            alt={`${loginContext.user.username} image`}
                            layout="fill"
                            objectFit="cover"
                            blurDataURL={DEFAULT_IMAGE_PLACEHOLDER}
                            placeholder="blur"
                            className="rounded-full object-cover"
                          />
                        )}
                      </div>

                      <div className="flex flex-col flex-1">
                        <span className="text-15 font-poppins leading-tight font-semibold text-white">
                          {loginContext.user.fullname}
                        </span>
                        <span className="text-13 font-inter leading-tight font-medium text-gray-300">
                          @{loginContext.user.username}
                        </span>
                        {loginContext.user.instagramData && (
                          <span className="flex items-center gap-2 mt-3 text-13 font-inter leading-tight font-medium text-gray-300">
                            @{loginContext.user.instagramData.username}
                            <Image
                              src="/static/icons/instagram.svg"
                              width={16}
                              height={16}
                              alt="instagram-verified"
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </a>
                </Link>

                <div className={linkClass} onClick={handleLogout}>
                  <Image
                    src="/static/icons/logout.svg"
                    alt="logout icon"
                    height={20}
                    width={20}
                    objectFit="cover"
                    className="object-cover"
                  />
                </div>
              </div>
              {loginContext.artist && (
                <Link
                  passHref
                  href={getArtistUrl(loginContext.artist.username)}
                >
                  <a>
                    <div className="flex flex-row gap-x-2">
                      <div className="relative h-9 w-9">
                        <Image
                          src={getAvatar(loginContext.artist.avatar)}
                          alt={`${loginContext.artist.username} image`}
                          layout="fill"
                          objectFit="cover"
                          blurDataURL={DEFAULT_IMAGE_PLACEHOLDER}
                          placeholder="blur"
                          className="rounded-full object-cover"
                        />
                      </div>

                      <div className="flex flex-col flex-1">
                        <div className="flex flex-row items-center gap-2">
                          <span className="text-15 font-poppins leading-tight font-semibold text-white">
                            {loginContext.artist.name}
                          </span>

                          <Verified size={14} />
                        </div>
                        <span className="text-13 font-inter leading-tight font-medium text-gray-300">
                          @{loginContext.artist.username}
                        </span>
                      </div>
                    </div>
                  </a>
                </Link>
              )}
            </div>
            <nav className="flex flex-col">
              <div className="flex flex-col space-y-2">
                {userNavigationLinks.map((item) => {
                  const isCurrent = router.pathname === item.href;
                  return (
                    <Link
                      key={item.name}
                      href={item.href}
                      aria-current={isCurrent ? "page" : undefined}
                      passHref
                    >
                      <div className={linkClass}>
                        {item.icon && (
                          <Image
                            src={item.icon}
                            alt={`${item.name} image`}
                            height={20}
                            width={20}
                          />
                        )}
                        <a>{item.name}</a>
                      </div>
                    </Link>
                  );
                })}
              </div>
              <div className="flex flex-col gap-3 text-13 pt-5 cursor-pointer font-poppins leading-normal text-gray-300">
                <Link passHref href="/about-us">
                  <a>
                    <span>About us</span>
                  </a>
                </Link>
              </div>
              <div className="flex flex-col gap-3 text-13 pt-5 cursor-pointer font-poppins leading-normal text-gray-300">
                <Link passHref href="/faq">
                  <a>
                    <span>FAQ</span>
                  </a>
                </Link>
              </div>
            </nav>
            <div className="flex flex-col space-y-4 border-t mt-5 text-gray-300 pt-3">
              <h3>Notifications</h3>
              {loginContext.notifications.length > 0 ? (
                <div></div>
              ) : (
                <div className="flex flex-col items-center py-4 px-2 bg-[#37383e] rounded-[8px]">
                  <Image
                    src="/static/icons/icon_bell.svg"
                    width={48}
                    height={48}
                    alt="no-notifications"
                  />
                  <p className="text-white text-center text-[13px]">
                    You have no notifications.
                  </p>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {!showSignIn ? (
              <>
                <div className="flex flex-col mb-7 items-center text-white gap-5 w-max self-center">
                  <h5 className="text-sm font-poppins leading-tight font-semibold">
                    Create your KLOOV account
                  </h5>
                  <Button
                    as="a"
                    href="/signup"
                    primary
                    className="px-7 py-3 mb-1 w-full rounded-sm text-xs font-inter leading-normal font-medium"
                  >
                    Sign up
                  </Button>
                  <small>Already have an account? </small>
                  <Button
                    onClick={() => setShowSignIn(true)}
                    href="/signin"
                    inverted
                    className="px-7 py-3 mb-1 w-full rounded-sm text-xs font-inter leading-normal font-medium"
                  >
                    Sign in
                  </Button>
                  <small>Or</small>

                  <GoogleSignInButton id="googleMenuLoginDiv" />
                </div>
                <div className="flex flex-col gap-3 text-13 pt-5 cursor-pointer font-poppins leading-normal text-gray-300">
                  <Link passHref href="/about-us">
                    <a>
                      <span>About us</span>
                    </a>
                  </Link>
                  <Link passHref href="/faq">
                    <a>
                      <span>FAQ</span>
                    </a>
                  </Link>
                  <Link passHref href="/faq">
                    <a>
                      <span>For Artists, Creators & Business</span>
                    </a>
                  </Link>
                </div>
              </>
            ) : (
              <SignInModal onClose={() => setShowSignIn(false)} />
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default MenuModal;
