import { FC, useEffect, MouseEvent } from "react";
import Image from "next/image";
import { MdClose } from "react-icons/md";
import classNames from "classnames";
import Link from "next/link";
import {
  getEtherscanAddressUrl,
  getEtherscanTransactionUrl,
} from "@/shared/utils/createUrls";
import { formatTransactionHash } from "@/shared/utils/strings";
import { useDispatch } from "react-redux";
import { closeNotification } from "../redux/actions/notification";
import { useNotification } from "../redux/selectors";
import { NotificationType, TransactionStatus } from "../utils/notification";
import { Router } from "next/router";
import Modal from "./Modal";
import Button from "./Button";

const Notification: FC = () => {
  const dispatch = useDispatch();
  const notification = useNotification();

  const baseClass = "rounded-full absolute border-2 h-[14px] w-[14px]";
  const normal = "bg-white";

  const _statusClassName = (status: TransactionStatus | undefined) => {
    return classNames(
      baseClass,
      !status && normal,
      status === "SUCCESS" && "bg-green-500",
      status === "FAILED" && "bg-red-500",
      status === "PENDING" && "bg-blue-500 animate-pulse"
    );
  };

  // Close notification on routeChangeComplete
  useEffect(() => {
    const onRouteChangeComplete = () => {
      dispatch(closeNotification());
    };
    if (notification && notification.closeOnRouteChange !== false) {
      Router.events.on("routeChangeComplete", onRouteChangeComplete);
      return () =>
        Router.events.off("routeChangeComplete", onRouteChangeComplete);
    }
  }, [dispatch, notification]);

  const handleOnBtnClick = async (e: MouseEvent) => {
    if (!notification) {
      return;
    }

    if (notification.onClick) {
      await notification.onClick(e);
    }

    if (notification.closeOnBtnClick) {
      await dispatch(closeNotification());
    }
  };

  if (!notification) {
    return null;
  }

  return notification.type === NotificationType.STRIPE_PAYMENT ? (
    <Modal
      className="flex justify-center items-center w-[300px] h-[510px]  font-inter"
      isOpen={notification ? true : false}
      //onClose={() => dispatch(closeNotification())}
    >
      <>
        {notification.status === "SUCCESS" ? (
          <div className="flex flex-col space-y-5 justify-between items-center">
            <div className="bg-gray-900 rounded-[12px] px-10 py-2 text-[12px] text-brand font-bold text-center">
              {notification.title}
            </div>
            <div className="flex flex-col space-y-4 items-center">
              {notification.image && (
                <Image
                  src={notification.image}
                  width={244}
                  height={244}
                  alt="succes cover"
                />
              )}
              <Button
                primary
                className="w-[196px] h-[42px] rounded-[8px] py-2 px-5 font-bold text-[14px]"
                onClick={notification.onClick}
              >
                {notification.btnText}
              </Button>
            </div>
            {notification.extraInfo && (
              <div className="w-full flex flex-col bg-gray-900 rounded-[8px] py-5 px-10 text-[12px] text-center">
                {notification.extraInfo.image && (
                  <Image
                    src={notification.extraInfo.image}
                    height={18}
                    width={18}
                    alt="extra info"
                  />
                )}
                <p>{notification.extraInfo.text}</p>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col space-y-5 justify-between items-center">
            <div className="bg-gray-900 rounded-[12px] px-10 py-2 text-[12px] text-[#ff7171] font-bold text-center">
              {notification.title}
            </div>
            <div className="flex flex-col space-y-4 items-center">
              {notification.image && (
                <Image
                  src={notification.image}
                  width={244}
                  height={244}
                  alt="succes cover"
                />
              )}
              <Button
                primary
                className="w-[196px] h-[42px] rounded-[8px] py-2 px-5 font-bold text-[14px]"
                onClick={notification.onClick}
              >
                {notification.btnText}
              </Button>
            </div>
            {notification.extraInfo && (
              <div className="w-full flex flex-col bg-gray-900 rounded-[8px] py-5 px-10 text-[12px] text-center">
                {notification.extraInfo.image && (
                  <Image
                    src={notification.extraInfo.image}
                    height={18}
                    width={18}
                    alt="extra info"
                  />
                )}
                <p>{notification.extraInfo.text}</p>
              </div>
            )}
          </div>
        )}
      </>
    </Modal>
  ) : (
    <Modal
      className="w-[343px]"
      isOpen={notification ? true : false}
      //onClose={() => dispatch(closeNotification())}
    >
      <>
        {notification.bgImage && (
          <div>
            <Image
              src={notification.bgImage}
              alt="notification background image"
              aria-label="notification background image"
              layout="fill"
              className="rounded-lg"
            />
          </div>
        )}
        <div
          className={`flex flex-col rounded-sm flex-1 p-5 w-full ${
            notification.bgImage && "backdrop-contrast-50"
          }`}
        >
          <div className="self-end">
            {!notification.closeDisabled && (
              <MdClose
                className="cursor-pointer"
                size={25}
                onClick={() => dispatch(closeNotification())}
              />
            )}
          </div>
          <div className="flex flex-col flex-1 justify-center gap-5">
            <div className="flex flex-col gap-7 items-center justify-center">
              {notification.image && (
                <div>
                  <Image
                    src={notification.image}
                    alt="notification image"
                    height="100"
                    width="100"
                    className="object-contain"
                  />
                </div>
              )}
              <h2 className="text-lg font-inter leading-tight text-center font-semibold">
                {notification.title}
              </h2>
            </div>
            <div className="flex flex-col border-1 border-t border-gray-350 items-center w-full gap-5 pt-12 mb-6">
              {notification.status && (
                <div className="relative w-12 h-12 rounded-full border-4 border-gray-350">
                  <Image
                    src="/static/icons/icon_profile.png"
                    alt="profile icon"
                    layout="fill"
                    className="object-contain"
                  />

                  <span className={_statusClassName(notification.status)} />
                </div>
              )}
              <p className="text-xs font-inter break-words leading-normal text-center max-w-[199px] font-medium whitespace-pre-wrap">
                {notification.description}
              </p>
              {notification.transactionHash && notification.chain && (
                <div>
                  <Link
                    passHref
                    href={getEtherscanTransactionUrl(
                      notification.transactionHash,
                      notification.chain
                    )}
                  >
                    <a target="_blank">
                      Tx hash:{" "}
                      {formatTransactionHash(notification.transactionHash)}
                    </a>
                  </Link>
                </div>
              )}
              {notification.buyerAddress && notification.chain && (
                <div>
                  <Link
                    passHref
                    href={getEtherscanAddressUrl(
                      notification.buyerAddress,
                      notification.chain
                    )}
                  >
                    <a target="_blank">
                      KLOOV wallet:{" "}
                      {formatTransactionHash(notification.buyerAddress)}
                    </a>
                  </Link>
                </div>
              )}
              {notification.btnText && (
                <button
                  className="text-white rounded-sm border py-2 px-12"
                  onClick={handleOnBtnClick}
                >
                  {notification.btnText}
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default Notification;
