import { useEffect, useState } from "react";
import useCurrentUrl from "./useCurrentUrl";

const useScrollDetection = (
  maxScroll: number,
  onScrollDown: () => void,
  minScroll?: number,
  onScrollUp?: () => void,
  blocker?: boolean
) => {
  const currentUrl = useCurrentUrl();
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = async (e: Event) => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    setScrollY(st);
    if (!blocker) {
      // downscroll code
      if (st > maxScroll) {
        await onScrollDown();
      } else if (minScroll && onScrollUp) {
        if (st < minScroll) {
          await onScrollUp();
        }
      }
    }
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", handleScroll);
    }
    if (!blocker) {
      watchScroll();
    } else {
      document.body.removeEventListener("scroll", handleScroll);
    }
    return () => document.body.removeEventListener("scroll", handleScroll);
  }, [currentUrl, blocker]);
};

export default useScrollDetection;
