import classNames from "classnames";
import { ChangeEvent, FC, ReactNode } from "react";

interface Props {
  className?: string;
  label: ReactNode;
  name: string;
  id?: string;
  required?: boolean;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<Props> = ({
  className,
  label,
  name,
  id = name,
  ...props
}) => {
  return (
    <div
      className={classNames(
        "flex flex-row items-center w-full space-x-2",
        className
      )}
    >
      <label className={classNames("control control-checkbox", className)}>
        {label}
        <input type="checkbox" name={name} id={id} {...props} />
        <div className="control_indicator"></div>
      </label>
    </div>
  );
};

export default Checkbox;
