import classNames from "classnames";
import Image from "next/image";
import React, { FC } from "react";

interface Props {
  className?: string;
  size: number;
}
const Verified: FC<Props> = ({ className, size }) => {
  const _class = classNames(
    "icon-Verified-Icon-Fill text-[14px]  flex justify-center items-center",
    className
  );
  return (
    <Image
      src="/static/icons/icon_tick.svg"
      width={size}
      height={size}
      alt="tick"
    />
  );
};

export default Verified;
