import { ActionType } from "../actions/loginContext";
import { LoginContext } from "@/shared/models";
import { Reducer } from "redux";

interface State {
  loginContext: LoginContext;
  loading: boolean;
}

const initialState: State = {
  loginContext: {
    user: null,
    notifications: [],
    artist: null,
  },
  loading: false,
};

const loginContextReducer: Reducer<State, ActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "AUTH_SET_LOGIN_CONTEXT":
      return {
        ...state,
        loginContext: action.payload,
        loading: false,
      };
    case "AUTH_START_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "AUTH_STOP_LOADING":
      return {
        ...state,
        loading: false,
      };
    case "AUTH_LOGOUT":
      return {
        ...state,
        loading: false,
        loginContext: {
          user: null,
          artist: null,
          notifications: [],
        },
      };
    case "UPDATE_USER":
      return {
        ...state,
        loading: false,
        loginContext: action.payload,
      };
    default:
      return state;
  }
};

export default loginContextReducer;
