import classNames from "classnames";
import React, { forwardRef, ReactNode } from "react";

const baseClass =
  "flex justify-center items-center font-inter font-medium leading-normal focus:outline-none text-13";
const primaryButtonClass =
  "text-black bg-brand border transition border-brand hover:bg-transparent hover:border hover:border-brand hover:text-brand";
const invertedButtonClass =
  "text-brand bg-transparent transition border border-brand hover:bg-brand   hover:text-black";
const redButtonClass =
  "text-[#ff7171] bg-transparent transition border border-[#ff7171] hover:bg-[#ff7171]   hover:text-black";
const secondaryButtonClass = "text-white";
const disabledButtonClass = "bg-gray-900 text-[#5E5E5E] cursor-not-allowed";

interface Props {
  as?: "a" | "button";
  id?: string;
  className?: string;
  secondary?: boolean;
  primary?: boolean;
  disabled?: boolean;
  inverted?: boolean;
  red?: boolean;
  children: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
  type?: "button" | "submit";
  target?: "_blank" | "_self" | "_parent" | "_top" | "framename";
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    {
      as: Component = "button",
      className,
      primary = false,
      secondary = false,
      disabled = false,
      inverted = false,
      red = false,
      children,
      id,
      ...props
    },
    ref
  ) => {
    const _class = classNames(
      baseClass,
      {
        [primaryButtonClass]:
          primary && !inverted && !red && !secondary && !disabled,
        [invertedButtonClass]:
          inverted && !primary && !red && !secondary && !disabled,
        [redButtonClass]:
          red && !inverted && !primary && !secondary && !disabled,
        [secondaryButtonClass]:
          secondary && !primary && !red && !inverted && !disabled,
        [disabledButtonClass]: disabled,
      },
      className
    );
    return (
      <Component
        {...props}
        id={id}
        disabled={disabled}
        className={_class}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={ref as any}
      >
        {children}
      </Component>
    );
  }
);

Button.displayName = "Button";

export default Button;
