import { User } from "@/shared/models";
import { isAdmin } from "@/shared/utils/roles";

enum NavigationVisibility {
  ALL,
  LOGOUT,
  AUTHENTICATED,
  ADMIN,
}

interface NavigationItem {
  name: string;
  href: string;
  icon?: string;
  currentIcon?: string;
  alt?: string;
  visibility: NavigationVisibility;
}

const shouldDisplayLink = (
  item: NavigationItem,
  currentUser: User | null,
  isAdmin = false
) => {
  if (item.visibility === NavigationVisibility.AUTHENTICATED && !currentUser) {
    return false;
  }

  if (item.visibility === NavigationVisibility.LOGOUT && currentUser) {
    return false;
  }

  if (item.visibility === NavigationVisibility.ADMIN && !isAdmin) {
    return false;
  }

  return true;
};

const navigationLinks: NavigationItem[] = [
  {
    name: "My account",
    icon: "/static/icons/my_account.svg",
    currentIcon: "/static/icons/my_account_current.svg",
    alt: "my account icon",
    href: "/u/config/my-account",
    visibility: NavigationVisibility.AUTHENTICATED,
  },
  {
    name: "Settings",
    icon: "/static/icons/settings.svg",
    currentIcon: "/static/icons/settings_current.svg",
    alt: "settings icon",
    href: "/u/config/settings",
    visibility: NavigationVisibility.AUTHENTICATED,
  },
  {
    name: "Admin Panel",
    icon: "/static/icons/admin.svg",
    currentIcon: "/static/icons/admin_current.svg",
    alt: "admin icon",
    href: "/u/config/admin",
    visibility: NavigationVisibility.ADMIN,
  },
];

export const getNavigationLinks = (currentUser: User | null) => {
  const isAdminUser = isAdmin(currentUser);
  return navigationLinks.filter((link) =>
    shouldDisplayLink(link, currentUser, isAdminUser)
  );
};
