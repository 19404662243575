import classNames from "classnames";
import React, { FC, ReactNode, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Button from "./Button";
import Label from "./Label";

type Type =
  | "text"
  | "password"
  | "email"
  | "search"
  | "number"
  | "datetime-local";

interface Props {
  name: string;
  value?: string | number;
  label?: string;
  className?: string;
  inputClassName?: string;

  id?: string;
  step?: number;
  suffix?: ReactNode;
  prefix?: ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  type?: Type;
  disabled?: boolean;
  placeholder?: string;
  min?: number;
  ref?: React.MutableRefObject<HTMLInputElement | null>;
  autoFocus?: boolean;
}

const Input: FC<Props> = ({
  className,
  inputClassName,
  label,
  name,
  id = name,
  prefix,
  suffix,
  type = "text",
  disabled = false,
  ref,
  autoFocus = false,
  ...props
}) => {
  const [hasFocus, setFocus] = useState(false);
  const isPassword = type === "password";
  const [showPassword, setShowPassword] = useState(false);

  // Clear placeholder on focus & restore when on blur
  const placeholder = hasFocus ? "" : props.placeholder;

  const mainInputClass = classNames(
    "flex-grow flex align-center items-center py-2 px-4 bg-gray-450 text-gray-300 rounded-[0.35rem]",
    prefix && "pl-4",
    inputClassName
  );
  const inputClassNames = classNames(
    "placeholder-gray-300 bg-gray-450 text-gray-700 text-13 font-inter leading-normal font-medium w-full outline-none focus:outline-none appearance-none",
    prefix && "pl-2",
    disabled && "cursor-not-allowed",
    inputClassName
  );

  return (
    <div className={className}>
      {label && (
        <Label id={id} hasFocus={hasFocus}>
          {label}
        </Label>
      )}
      <div className="flex flex-row w-full">
        <div className={mainInputClass}>
          {prefix}
          <input
            {...props}
            id={id}
            name={name}
            disabled={disabled}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
            className={inputClassNames}
            type={isPassword ? `${showPassword ? "text" : "password"}` : type}
            autoComplete="off"
            placeholder={placeholder}
            ref={ref}
            autoFocus={autoFocus}
          />
          {isPassword && (
            <span onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          )}
        </div>
        {suffix && (
          <Button
            primary
            className="text-center focus:outline-none hover:bg-brand-light hover:text-black px-4 rounded-tr-sm rounded-br-sm text-black bg-brand"
          >
            {suffix}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Input;
