import classNames from "classnames";
import React, { FC, ChangeEvent, ReactNode } from "react";
import { IoIosSearch } from "react-icons/io";
import useMediaQuery from "../hook/useMediaQuery";
import Input from "./Input";

interface Props {
  expanded?: boolean;
  setExpanded?: (value: boolean) => void;
  value: string;
  setValue: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  prefix?: ReactNode;
  placeholder?: string;
}
const SearchBar: FC<Props> = ({
  expanded = false,
  setExpanded,
  value,
  setValue,
  className,
  prefix = <IoIosSearch width={48} />,
  placeholder = "Search artists, collections...",
}) => {
  const handleSetQuery = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      if (setExpanded) setExpanded(false);
    }
    setValue(e);
  };

  const _class = classNames("w-full md:w-[343px] h-[40px]", className);

  return (
    <>
      {expanded ? (
        <div className="flex w-full items-center h-[40px]">
          <Input
            prefix={prefix}
            value={value}
            name="searchQuery"
            placeholder={placeholder}
            inputClassName={className}
            className={_class}
            onChange={handleSetQuery}
            autoFocus
          />
        </div>
      ) : (
        <div
          className="flex items-center justify-center cursor-pointer bg-gray-450 p-[10px] h-[40px] rounded-[8px]"
          onClick={() => setExpanded && setExpanded(true)}
        >
          <IoIosSearch size={20} color="#a3a3a3" />
        </div>
      )}
    </>
  );
};

export default SearchBar;
