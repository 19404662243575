import { Reducer } from "redux";
import { ActionType } from "../actions/navbar/index";

interface State {
  expanded: boolean;
}

const initialState: State = {
  expanded: true,
};

const profileModalReducer: Reducer<State, ActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "COLLAPSE_NAVBAR":
      return {
        ...state,
        expanded: action.payload,
      };
    case "EXPAND_NAVBAR":
      return {
        ...state,
        expanded: action.payload,
      };
    default:
      return state;
  }
};

export default profileModalReducer;
